import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {LoginComponent} from './pages/login/login.component';
import {ReportComponent} from './pages/report/report.component';
import {TicketComponent} from './pages/ticket/ticket.component';
import {ComplaintComponent} from './pages/complaint/complaint.component';
import {HomeComponent} from './pages/home/home.component';
import {ManageUserComponent} from './pages/users/manage-user/manage-user.component';
import {ForgotPasswordComponent} from './pages/password-management/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './pages/password-management/reset-password/reset-password.component';
import {VerifyEmailComponent} from './pages/users/verify-email/verify-email.component';
import {RecordRtcComponent} from "./pages/record-rtc/record-rtc.component";


const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'complaint',
    component: ComplaintComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ticket',
    component: TicketComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'report',
    component: ReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'update-profile',
    component: ManageUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-user',
    component: ManageUserComponent
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent
  },
  {
    path: 'record-rtc',
    component: RecordRtcComponent
  },
  {
    path: '**',
    component: HomeComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
