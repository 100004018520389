import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import { CommonService } from '../../../_services/common.service';
import { GeoLocationService } from '../../../_services/geo-location.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {errorValidateAdmin, email, date, diff_years, MustMatch, number, passwordPolicy, formatDate} from '../../../_helpers/utility';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DateAdapter } from '@angular/material';
import {MapsAPILoader, LatLngLiteral} from '@agm/core';
import {parse} from "ts-node";
import {AutocompleteComponent} from '../../../google-places.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {
  constructor(
    private router: Router,
    private service: CommonService,
    private geoLocationService: GeoLocationService,
    private formBuilder: FormBuilder,
    private activeroute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private dateAdapter: DateAdapter<Date>,
    private mapsAPILoader: MapsAPILoader,
    private zone: NgZone,
    private modalService: NgbModal,
    private autoCompleteComponent: AutocompleteComponent
  ) {
    this.activeroute.queryParams.subscribe(params => {
      if (params['id']) {
        this.id = params['id'];
      }
    });

    this.dateAdapter.setLocale('en-GB');
  }
  address: Object;
  establishmentAddress: Object;

  formattedAddress: string;
  formattedEstablishmentAddress: string;

  phone: string;
  manageUserFormGroup: FormGroup;
  id: any = '';
  detailTitle: any = '';
  receivedParentMessages=' REGISTER ';
  isEdit = false;
  isCheck = false;
  buttonText = 'Save';
  userToken:any;
  route:any;
  toDay = new Date();
  banksList = [];
  regionsList = [];
  isLoading = false;
  coordinates: any;
  latitude = 26.08755423600553;
  longitude = 45.263671875;
  lt: any;
  lg: any;
  userDetails: any;
  userData: any;
  userRole: any;


  getAddress(place: object) {
    this.address = place['formatted_address'];
    this.phone = this.getPhone(place);
    this.formattedAddress = place['formatted_address'];
    this.zone.run(() => this.formattedAddress = place['formatted_address']);
    this.manageUserFormGroup.controls['street'].setValue(this.address);
    this.geocoding(this.address);
  }

  getEstablishmentAddress(place: object) {
    this.establishmentAddress = place['formatted_address'];
    this.phone = this.getPhone(place);
    this.formattedEstablishmentAddress = place['formatted_address'];
    this.zone.run(() => {
      this.formattedEstablishmentAddress = place['formatted_address'];
      this.phone = place['formatted_phone_number'];
    });
  }

  getAddrComponent(place, componentTemplate) {
    let result;

    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
    return;
  }

  getStreetNumber(place) {
    const COMPONENT_TEMPLATE = { street_number: 'short_name' },
      streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return streetNumber;
  }

  getStreet(place) {
    const COMPONENT_TEMPLATE = { route: 'long_name' },
      street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }

  getCity(place) {
    const COMPONENT_TEMPLATE = { locality: 'long_name' },
      city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }

  getState(place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' },
      state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getDistrict(place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' },
      state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getCountryShort(place) {
    const COMPONENT_TEMPLATE = { country: 'short_name' },
      countryShort = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return countryShort;
  }

  getCountry(place) {
    const COMPONENT_TEMPLATE = { country: 'long_name' },
      country = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return country;
  }

  getPostCode(place) {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' },
      postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }

  getPhone(place) {
    const COMPONENT_TEMPLATE = { formatted_phone_number: 'formatted_phone_number' },
      phone = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return phone;
  }



  ngOnInit() {


    this.userToken = (localStorage.getItem('userToken'));
    const authData = (localStorage.getItem('authData'));

    if (this.userToken) {
      this.userData = JSON.parse(authData);
      this.userData = this.userData && this.userData[0] !== undefined && this.userData[0].user !== undefined ? this.userData[0].user : null;
      this.userRole = this.userData && this.userData.user_role_id !== undefined ? this.userData.user_role_id : null;
      this.lt = this.userData && this.userData.latitude !== undefined ? parseFloat(this.userData.latitude) : this.latitude;
      this.lg = this.userData && this.userData.longitude !== undefined ? parseFloat(this.userData.longitude) : this.longitude;
    }

    const _this = this;
    //
    //
    // JQuery(".from.datetimepicker")
    //   .datetimepicker({
    //     useCurrent: false,
    //     format: "DD-MM-YYYY",
    //     viewDate: moment(new Date())
    //       .hours(0)
    //       .minutes(0)
    //       .seconds(0)
    //       .milliseconds(0)
    //   })
    //   .on("dp.change", function(e) {
    //     let name = e.target.name;
    //     _this.manageUserFormGroup.patchValue({
    //       [name]: e.target.value
    //     });
    //   });
    // JQuery(".to.datetimepicker")
    //   .datetimepicker({
    //     useCurrent: false,
    //     format: "DD-MM-YYYY",
    //     viewDate: moment(new Date())
    //       .hours(23)
    //       .minutes(59)
    //       .seconds(0)
    //       .milliseconds(0)
    //   })
    //   .on("dp.change", function(e) {
    //     let name = e.target.name;
    //     _this.manageUserFormGroup.patchValue({
    //       [name]: e.target.value
    //     });
    //   });
    //

    if (this.id) {
      this.isEdit = true;
      this.receivedParentMessages = ' UPDATE ';
      this.buttonText = 'Update';
      if (this.userRole == 1) {
        this.manageUserFormGroup = this.formBuilder.group({
            id: [''],
            name: ['', Validators.required],
            contact_name: ['', Validators.required],
            contact: ['', Validators.required],
            street: ['', Validators.required],
            city: ['', Validators.required],
            region_id: ['', Validators.required],
            latitude: ['', Validators.required],
            longitude: ['', Validators.required],
            email: [{value: '', disabled: true}, [Validators.required]],
            password: ['', [Validators.required]],
            password_confirmation: ['', [Validators.required]]
          },
          {
            validator: [
              MustMatch('password', 'password_confirmation'),
              passwordPolicy('password'),
              email('email'),
            ]
          });
      } else if (this.userRole == 2) {
        this.manageUserFormGroup = this.formBuilder.group({
            id: [''],
            name: ['', Validators.required],
            bank_id: [{value: '', disabled: true}, Validators.required],
            contact_name: ['', Validators.required],
            contact: ['', Validators.required],
            street: ['', Validators.required],
            city: ['', Validators.required],
            region_id: ['', Validators.required],
            latitude: ['', Validators.required],
            longitude: ['', Validators.required],
            email: [{value: '', disabled: true}, [Validators.required]],
            password: ['', [Validators.required]],
            password_confirmation: ['', [Validators.required]]
          },
          {
            validator: [
              MustMatch('password', 'password_confirmation'),
              passwordPolicy('password'),
              email('email'),
            ]
          });

      } else if (this.userRole == 3) {
        this.manageUserFormGroup = this.formBuilder.group({
            id: [''],
            name: ['', Validators.required],
            contact_name: ['', Validators.required],
            contact: ['', Validators.required],
            street: ['', Validators.required],
            city: ['', Validators.required],
            region_id: ['', Validators.required],
            latitude: ['', Validators.required],
            longitude: ['', Validators.required],
            email: [{value: '', disabled: true}, [Validators.required]],
            password: ['', [Validators.required]],
            password_confirmation: ['', [Validators.required]]
          },
          {
            validator: [
              MustMatch('password', 'password_confirmation'),
              passwordPolicy('password'),
              email('email'),
            ]
          });
      } else {
        this.manageUserFormGroup = this.formBuilder.group({
            id: [''],
            name: ['', Validators.required],
            pos_terminal_id: [{value: '', disabled: true}, Validators.required],
            bank_id: [{value: '', disabled: true}, Validators.required],
            contact_name: ['', Validators.required],
            contact: ['', Validators.required],
            street: ['', Validators.required],
            city: ['', Validators.required],
            region_id: ['', Validators.required],
            latitude: ['', Validators.required],
            longitude: ['', Validators.required],
            email: [{value: '', disabled: true}, [Validators.required]],
            password: ['', [Validators.required]],
            password_confirmation: ['', [Validators.required]]
          },
          {
            validator: [
              MustMatch('password', 'password_confirmation'),
              passwordPolicy('password'),
              email('email'),
            ]
          });
      }
      this.getUserDetails();
    } else {
      this.manageUserFormGroup = this.formBuilder.group({
          id: [''],
          name: ['', Validators.required],
          pos_terminal_id: ['', Validators.required],
          bank_id: ['', Validators.required],
          contact_name: ['', Validators.required],
          contact: ['', Validators.required],
          street: ['', Validators.required],
          city: ['', Validators.required],
          region_id: ['', Validators.required],
          latitude: ['', Validators.required],
          longitude: ['', Validators.required],
          email: ['', [Validators.required]],
          password: ['', [Validators.required]],
          password_confirmation: ['', [Validators.required]]
        },
        {
          validator: [
            MustMatch('password', 'password_confirmation'),
            passwordPolicy('password'),
            email('email'),
          ]
        });
      this.getCurrentGeoLocation();
    }

    this.getRegionsList();
    this.getBanksList();
  }

  public centerChanged(coords: LatLngLiteral) {
    this.latitude = coords.lat;
    this.longitude = coords.lng;
  }

  public mapReady(map) {
    map.addListener('dragend', () => {
      // do something with centerLatitude/centerLongitude
    });
  }

  getCurrentGeoLocation() {
    this.geoLocationService.getPosition().then(pos => {
      this.latitude = pos.lat;
      this.latitude = pos.lng;
      this.lt = pos.lat;
      this.lg = pos.lng;
      this.manageUserFormGroup['controls'].latitude.setValue(this.latitude);
      this.manageUserFormGroup['controls'].longitude.setValue(this.longitude);
      // console.log(`Positon: ${pos.lng} ${pos.lat}`);
    }, error => {
      this.lt = this.latitude;
      this.lg = this.longitude;
      this.manageUserFormGroup['controls'].latitude.setValue(this.latitude);
      this.manageUserFormGroup['controls'].longitude.setValue(this.longitude);
    });
  }

  getRegionsList() {
    this.service.getRequest('get-regions-list').subscribe( (serviceresponse: any) => {
        if (serviceresponse.code === 200) {
          this.regionsList = serviceresponse.result ? serviceresponse.result : [];
        }
      },
      error => {
        errorValidateAdmin(error);
      }
    );
  }

  getBanksList() {
    this.service.getRequest('get-banks-list').subscribe( (serviceresponse: any) => {
        if (serviceresponse.code === 200) {
          this.banksList = serviceresponse.result ? serviceresponse.result : [];
        }
      },
      error => {
        errorValidateAdmin(error);
      }
    );
  }

  warning(type) {
    if (type == 'dob') {
      Swal.fire({
          title: '<strong>Please Note</strong>',
          html:
            'You must be <b>18 years or above</b> to proceed.'
        }
      );
    }
    if (type == 'password') {
      Swal.fire({
          title: '<strong>Password Policy</strong>',
          html: '<hr><ul style="text-align: justify">' +
            '<li>Password length must be equal to or greater than 8</li>' +
            '<li>Password must contain an uppercase character</li>' +
            '<li>Password must contain a lowercase character</li>' +
            '<li>Password must contain a number</li>' +
            '<li>Password must contain a special character</li>' +
            '</ul>'
        }
      );
    }
  }

  geocoding(address) {
    this.geoLocationService.getLocation(address).subscribe(response => {
      if (response.status == 'OK'){
        this.latitude = response.results[0].geometry.location.lat;
        this.longitude = response.results[0].geometry.location.lng;
        this.lt = this.latitude;
        this.lg = this.longitude;
        this.manageUserFormGroup['controls'].latitude.setValue(this.latitude);
        this.manageUserFormGroup['controls'].longitude.setValue(this.longitude);
        this.setMarkerLocation();
      } else {
        console.log(response);
      }
    });
  }

  setMarkerLocation() {
    this.lt = this.latitude;
    this.lg = this.longitude;
    this.manageUserFormGroup['controls'].latitude.setValue(this.latitude);
    this.manageUserFormGroup['controls'].longitude.setValue(this.longitude);
  }

  checkLength($e, maxLength) {
    if ($e.target.value.length == maxLength) {
      if ( !($e.keyCode == 8 || $e.keyCode == 37 || $e.keyCode == 39 || $e.keyCode == 9 )) {
        $e.preventDefault();
      }
    }
  }

  onChooseLocation(e) {
    this.latitude = e.coords.lat;
    this.longitude = e.coords.lng;
  }

  resetForm() {
    this.manageUserFormGroup.reset();
    this.manageUserFormGroup.patchValue({
      pos_terminal_id: this.userDetails.pos_terminal_id,
      email: this.userDetails.email,
      bank_id: this.userDetails.bank_id,
    });
    }

  getUserDetails() {
    this.ngxService.start();
    this.service.postRequestCreator('user-detail', {id : this.id}, this.userToken).subscribe(
      (serviceresponse: any) => {
        this.ngxService.stop();
        // console.log(serviceresponse);
        if (serviceresponse.code === 200) {
          this.userDetails = serviceresponse.result;
          this.manageUserFormGroup.patchValue({
            id: serviceresponse.result.id,
            name: serviceresponse.result.name,
            pos_terminal_id: serviceresponse.result.pos_terminal_id,
            email: serviceresponse.result.email,
            password: serviceresponse.result.remember_password,
            password_confirmation: serviceresponse.result.remember_password,
            bank_id: serviceresponse.result.bank_id,
            contact_name: serviceresponse.result.contact_name,
            contact: serviceresponse.result.contact,
            street: serviceresponse.result.street,
            city: serviceresponse.result.city,
            region_id: serviceresponse.result.region_id,
            latitude: serviceresponse.result.latitude,
            longitude: serviceresponse.result.longitude,
          });
          this.latitude = serviceresponse.result.latitude;
          this.longitude = serviceresponse.result.longitude;
          this.lt = this.latitude;
          this.lg = this.longitude;
          this.child.autocompleteInput = serviceresponse.result.street;
          this.child.autoInput = serviceresponse.result.street;
          this.setMarkerLocation();

        } else {
          Swal.fire('Oops...', 'Something went wrong!');
        }
      },
      error => {
        this.ngxService.stop();
        errorValidateAdmin(error);
      }
    );
  }
  @ViewChild(AutocompleteComponent, {static:false}) child;

  saveUser(formData) {
    let tempAddress = this.address;
    if(!this.address){
      if(this.child.autoInput) {
        tempAddress = this.child.autoInput;
        this.manageUserFormGroup.controls['street'].setValue(this.child.autoInput);
      }
    }
    if (this.manageUserFormGroup.invalid) {
      // Swal.fire('Oops...', 'Please Fill the form correctly!', 'error');
      return;
    }
    this.isLoading = true;
    this.ngxService.start();
    if (this.isEdit) {
      this.route = 'update-user';
      formData.id = this.id;
      formData.latitude = this.latitude;
      formData.longitude = this.longitude;
      formData.street = tempAddress;
    } else {

      if (!this.isCheck) {
        Swal.fire({
          html: '<b>' +  'Please Accept Terms and Conditions' + '</b>',
          showConfirmButton: true
        });
        return;
      }

      formData.street = tempAddress;
      this.route = 'register-user';
    }
    formData.user_role_id = 4;
    this.service.postRequestCreator(this.route, formData, this.userToken).subscribe(
      (serviceresponse: any) => {
        this.isLoading = false;
        this.ngxService.stop();
        if (serviceresponse.code === 200 || serviceresponse.code === 202) {
          Swal.fire({
            html: '<b>' +  serviceresponse.message + '</b>',
            showConfirmButton: true
          });
          if (this.route == 'update-user') {
            // tslint:disable-next-line:no-shadowed-variable
            this.service.getRequestCreator('user-profile', this.userToken).subscribe((serviceresponse) => {
              const response = serviceresponse;

              const authData = (localStorage.getItem('authData'));
              const userData = JSON.parse(authData);
              userData[0].user = response;
              localStorage.setItem('userName', (response['name']));
              localStorage.setItem('authData', JSON.stringify(userData));
              const currentUrl = '/';
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate([currentUrl]);
            }, error => {

            });
          }
          this.router.navigate(['/']);
        } else if (serviceresponse.code === 400) {
          Swal.fire('Oops...', 'Please Fill the form correctly!');
        } else {
          Swal.fire('Oops...', 'Something went wrong!');
        }
      },
      error => {
        this.isLoading = false;
        this.ngxService.stop();
        errorValidateAdmin(error);
      }
    );
  }

  updateTerms() {
    this.isCheck = !this.isCheck;
  }


  viewDetail(content, elementTitle) {
    this.detailTitle = elementTitle;
    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', animation: true, size: 'lg', windowClass : 'myCustomModalClass', centered: true, scrollable: true}).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
export var JQuery: any = window["jQuery"];
