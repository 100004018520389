import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  data;
  searchKey: any = '';
  actionVideoBlob: Blob ;
  uri = environment.apiUrl;
  API_URL = environment.apiUrl;
  API_BASE_URL = environment.apiBaseUrl;
  MAP_API;
  constructor(
    private http: HttpClient
  ) {
    this.MAP_API = environment.mapAPI;
  }


  login(credential) {
    const data = {email: credential.email, password: credential.password};
    return this.http.post(`${this.uri}/login`, data);
  }
  logout(route, token): Observable<{}> {
    const header = new HttpHeaders();
    const otherHeader = header.append('Authorization', 'Bearer' + token);
    return this.http.post<{}>(this.API_URL + '/' + route, null, { headers: otherHeader });
  }

  getRequest(route): Observable<{}> {
    return this.http.get<{}>(this.API_URL + '/' + route);
  }

  postRequest(route, param) {
    return this.http.post<{}>(this.API_URL + '/' + route, param);
  }

  getRequestCreator(route, token): Observable<{}> {
    const header = new HttpHeaders();
    const otherHeader = header.append('Authorization', 'Bearer' + token);
    return this.http.get<{}>(this.API_URL + '/' + route, { headers: otherHeader });
  }

  getDataRequestCreator(route , data, token): Observable<{}> {
    const header = new HttpHeaders();
    const otherHeader = header.append('Authorization', 'Bearer' + token);
    return this.http.get<{}>(this.API_URL + '/' + route + '/' + data, { headers: otherHeader });
  }

  getRequestLottery(route, lotteryId, token) {
    const header = new HttpHeaders();
    const otherHeader = header.append('Authorization', 'Bearer' + token);
    return this.http.get(this.API_URL + '/' + route, {params : {lottery_id : lotteryId}, headers: otherHeader});
  }

  postRequestCreator(route, param, token): Observable<{}> {
    const header = new HttpHeaders();
    const otherHeader = header.append('Authorization', 'Bearer' + token);
    return this.http.post<{}>(this.API_URL + '/' + route, param, { headers: otherHeader });
  }

  postRequestCreatorWithFile(route, param, token): Observable<{}> {
    const header = new HttpHeaders();

    header.append('Content-Type', 'multipart/form-data');
    header.append('Accept', 'application/json');
    const otherHeader = header.append('Authorization', 'Bearer' + token);
    return this.http.post<{}>(this.API_URL + '/' + route, param, { headers: otherHeader });
  }

  postRequestFilter(route, filter, pageNo, pageSize, token) {
    const header = new HttpHeaders();
    const otherHeader = header.append('Authorization', 'Bearer' + token);
    return this.http.post(this.API_URL + '/' + route, filter, {params : { page_no : pageNo, page_size: pageSize}, headers: otherHeader});
  }
}
