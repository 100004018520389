import {Component, OnInit, ViewChild, AfterViewInit, ElementRef} from '@angular/core';
import * as RecordRTC from 'recordrtc';
import {CommonService} from '../../_services/common.service';

@Component({
  selector: 'app-record-rtc',
  templateUrl: './record-rtc.component.html',
  styleUrls: ['./record-rtc.component.css']
})
export class RecordRtcComponent implements AfterViewInit {
  constructor(
    public service: CommonService,
  ) {
    // Do stuff
  }

  private stream: MediaStream;
  private recordRTC: any;

  @ViewChild('video', {static : true}) video: any;
  // @ViewChild('viewVideo', {static : true}) viewVideo: any;

  startRecord = false;
  stopRecord = true;
  isRecorded = false;

  videoBlob: Blob;

  ngAfterViewInit() {
    // set the initial state of the video
    let video: HTMLVideoElement = this.video.nativeElement;
    video.muted = false;
    video.controls = true;
    video.autoplay = false;
  }
  startRecording() {
    this.startRecord = true;
    this.stopRecord = false;
    let mediaConstraints = {
      audio: true,
      video: {
        width: {
          min: 320,
          max: 1280
        },
        height: {
          min: 240,
          max: 720
        }
      }
    };

    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  successCallback(stream: MediaStream) {
    var options = {
      mimeType: 'video/webm', // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 128000,
      bitsPerSecond: 128000 // if this line is provided, skip above two
    };
    this.stream = stream;
    this.recordRTC = RecordRTC(stream, options);
    this.recordRTC.startRecording();
    let video: HTMLVideoElement = this.video.nativeElement;
    // video.src = window.URL.createObjectURL(stream);
    video.srcObject = (stream);
    this.toggleControls();
  }

  errorCallback(stream: MediaStream) {

    console.log('error', stream);
  }

  toggleControls() {
    let video: HTMLVideoElement = this.video.nativeElement;
    video.muted = !video.muted;
    video.controls = !video.controls;
    video.autoplay = !video.autoplay;
  }

  stopRecording() {
    this.startRecord = false;
    this.stopRecord = true;
    this.isRecorded = true;
    let recordRTC = this.recordRTC;
    recordRTC.stopRecording(this.processVideo.bind(this));
    let stream = this.stream;
    let video: HTMLVideoElement = this.video.nativeElement;
    video.srcObject = this.stream;
    stream.getAudioTracks().forEach(track => track.stop());
    stream.getVideoTracks().forEach(track => track.stop());
  }
  processVideo(audioVideoWebMURL) {
    let video: HTMLVideoElement = this.video.nativeElement;
    // let viewVideo: HTMLVideoElement = this.viewVideo.nativeElement;
    let recordRTC = this.recordRTC;

    video.src = audioVideoWebMURL;
    // viewVideo.src = audioVideoWebMURL;
    // console.log(viewVideo);
    this.toggleControls();
    var recordedBlob = recordRTC.getBlob();
    this.videoBlob = recordedBlob;
    this.service.actionVideoBlob = recordedBlob;
    // this.playVideo(recordedBlob);
    console.log('this.videoBlob', this.service.actionVideoBlob);
    recordRTC.getDataURL(function (dataURL) { });
  }

  download() {
    this.recordRTC.save('video.webm');
  }

  //
  // playVideo(videoStream) {
  //   // let viewVideo: HTMLVideoElement = this.viewVideo.nativeElement;
  //   // viewVideo.src = window.URL.createObjectURL(videoStream.data);
  //
  //
  //   var video = document.getElementById("viewVideo");
  //   video.src = window.URL.createObjectURL(videoStream);
  // }

  // public readUrl(event) {
  //   this.loadingState = true;
  //   this.videoLoaded = false;
  //   if (event.target.files && event.target.files[0]) {
  //     this.selectedVideo = event.target.files[0];
  //     const reader = new FileReader();
  //     reader.onload = (data: any) => {
  //       this.playVideo(data.target.result);
  //     };
  //     reader.readAsDataURL(event.target.files[0]);
  //   } else {
  //     this.selectedVideo = null;
  //   }
  // }
}
