import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup , Validators, FormControl} from '@angular/forms';
import {Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CommonService} from '../../_services/common.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
// import {errorValidateAdmin} from '../../_helpers/utility';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  data: any;
  authData: any;
  userToken: any;
  errorMessage: any;
  isLoading = false;
  // siteKey = environment.siteKey;
  baseURL;
  constructor(
    private fb: FormBuilder,
    @Inject(DOCUMENT) document,
    private service: CommonService,
    private http: HttpClient,
    private router: Router,
    private ngxService: NgxUiLoaderService,
  ) {
    this.createForm();
  }


  createForm() {
    this.loginForm = this.fb.group({
        email: ['', Validators.required],
        password: ['', Validators.required]
      },
    );
  }

  ngOnInit() {

    this.initProcess();
  }

  initProcess(){
    const ls = localStorage.getItem('authData');
    this.userToken = localStorage.getItem('userToken');
    const data = JSON.parse(ls);
    if (data && data[0].token) {
      this.router.navigate(['/home']);
    }
  }


  resetError(){
    this.errorMessage = '';
  }

  logIn(credentials) {

    let _this = this;
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.ngxService.start();
    credentials.email = btoa(credentials.email);
    credentials.password = btoa(credentials.password);
    this.service.login(credentials).subscribe(response => {
      this.isLoading = false;
      this.ngxService.stop();
      this.data = response;
      // console.log(this.data);
      if (this.data.result && this.data.result.access_token) {
        this.authData = new  Array({
          token : this.data.result.access_token,
          token_type : this.data.result.token_type,
          expires_in : this.data.result.expires_in,
          user : this.data.result.user
        });

        localStorage.setItem('authData', JSON.stringify(this.authData));
        localStorage.setItem('userToken', (this.data.result.access_token));
        localStorage.setItem('userName', (this.data.result.user.name));
        localStorage.setItem('userEmail', (this.data.result.user.email));
        this.ngxService.stop();
        Swal.fire({
          html: '<b>Welcome to dashboard!</b>',
          showConfirmButton: false,
          timer: 1500
        });
        this.router.navigate(['/home']);
      } else {

        // this.errorMessage = 'Email/Password is incorrect! Try Again';
        // setTimeout( () => {
        //   _this.resetError();
        // }, 5000);
        Swal.fire({
          html: '<b>Email/Password is incorrect! Try Again</b>',
          showConfirmButton: true
        });
      }
    }, error => {
      this.isLoading = false;
      this.ngxService.stop();
      // this.errorMessage = 'Something went wrong! Try Again';
      // setTimeout( () => {
      //   _this.resetError();
      // }, 5000);
      Swal.fire({
        html: '<b>Something went wrong! Try Again</b>',
        showConfirmButton: true,
      });
    });
  }



}
