import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup , Validators, FormControl} from '@angular/forms';
import {Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CommonService} from '../../../_services/common.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {timer} from 'rxjs';
// import {errorValidateAdmin} from '../../_helpers/utility';
import Swal from 'sweetalert2';
import {email} from '../../../_helpers/utility';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    @Inject(DOCUMENT) document,
    private service: CommonService,
    private http: HttpClient,
    private router: Router,
    private ngxService: NgxUiLoaderService,
  ) {
    this.createForm();
  }
  forgotPasswordForm: FormGroup;
  data: any;
  authData: any;
  userToken: any;
  errorMessage: any;
  // siteKey = environment.siteKey;
  baseURL;
  timeLeft = 300;
  interval;
  canResend = true;
  invalidUser = 0;
  invalidUserMessage = 'Please enter valid email id';


  createForm() {
    this.forgotPasswordForm = this.fb.group({
        email: ['', Validators.required],
      }, {
      validator: [
        email('email'),
      ]
    }
    );
  }

  ngOnInit() {
    this.initProcess();
  }

  initProcess() {
    const ls = localStorage.getItem('authData');
    this.userToken = localStorage.getItem('userToken');
    const data = JSON.parse(ls);
    if (data && data[0].token) {
      this.router.navigate(['/home']);
    }
  }


  resetError() {
    this.errorMessage = '';
  }
  validateUser(e) {
    this.invalidUser = 0;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    const data = { email : e.target.value };
    this.service.postRequest('validate-user', data).subscribe(serviceresponse => {
      const response = serviceresponse;
      if ( response['status'] === 'error' && response['code'] === 400) {
        this.invalidUser = 1;
      } else if ( response['status'] === 'success') {
        this.invalidUser = 0;

        // if (response['result']) {
        //   // tslint:disable-next-line:one-variable-per-declaration
        //   let date1, date2;
        //   date1 = new Date();
        //   date2 = new Date( response['result'].created_at );
        //   const seconds = Math.abs(date1 - date2) / 1000;
        //   // tslint:disable-next-line:radix
        //   if (parseInt(String(seconds)) <= 300) {
        //     // tslint:disable-next-line:radix
        //     this.timeLeft = this.timeLeft - parseInt(String(seconds));
        //     const resetTime = this.timeLeft + 1;
        //     this.interval = setInterval(() => {
        //       if (this.timeLeft > 0) {
        //         this.canResend = false;
        //         this.timeLeft--;
        //       }
        //     }, 1000);
        //     setTimeout(() => {
        //       clearInterval(this.interval);
        //       this.canResend = true;
        //       this.timeLeft = 300;
        //     }, (resetTime * 1000));
        //   }
        // }

      }
    }, error => {
    });
  }


  sendMail(formData) {

    // tslint:disable-next-line:variable-name
    const _this = this;
    if (this.forgotPasswordForm.invalid || this.invalidUser) {
      return;
    }
    this.ngxService.start();
    this.service.postRequest('send-password-reset-link', formData).subscribe(serviceresponse => {
      this.ngxService.stop();
      const response = serviceresponse;
      if ( response['status'] === 'success' && response['code'] === 200) {
        Swal.fire({
          text: 'An email has been sent to your email address with password reset link. Please check your mail.',
          showConfirmButton: true
        });
        // this.startTimer();
      } else if ( response['status'] === 'error' && response['code'] === 404) {
        Swal.fire({
          text: this.invalidUserMessage,
          showConfirmButton: true
        });
      } else {
        Swal.fire({
          text: 'Something went wrong! Try Again',
          showConfirmButton: true,
        });
      }
    }, error => {
      this.ngxService.stop();
      Swal.fire({
        text: 'Something went wrong! Try Again',
        showConfirmButton: true,
      });
    });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.canResend = false;
        this.timeLeft--;
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(this.interval);
      this.canResend = true;
      this.timeLeft = 300;
    }, 300001);
  }
}
