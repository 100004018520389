import {Inject, Injectable} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
import {CommonService} from '../_services/common.service';


@Injectable({
  providedIn: 'root'
})


export class AuthGuard implements CanActivate {

  constructor(
    private service: CommonService,
    private router: Router,
  ) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    const url = state.url;
    const auth = this.isUserAuth(url);
    if (auth) {
      this.router.navigate([url]);
    }
    return true;
  }
  isUserAuth(url: string ) {
    const dataPacket = localStorage.getItem('authData');
    const data = JSON.parse(dataPacket);
    // console.log(data[0].token); return ;
    if (data && data[0].token) {
      return;
    }
    this.router.navigate(['']);
    return false;
  }
}
