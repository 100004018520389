import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import {CommonService} from '../../_services/common.service';
import {errorValidateAdmin} from '../../_helpers/utility';


export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/home',     title: 'Home',         icon: 'nc-bank',       class: '' },
  { path: '/complaint',          title: 'Complaint',      icon: 'nc-single-02',  class: '' },
  { path: '/ticket',         title: 'Ticket',  icon: 'nc-tile-56',    class: '' },
  { path: '/report',         title: 'Report',  icon: 'nc-tile-56',    class: '' },
];

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent {
  data: any;
  userName: any;
  userToken: any;
  userData: any;
  userRole: any;
  href: any;
  isOpened: any = true;
  panelName: string;
  public menuItems: any[];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    public service: CommonService,
    private ngxService: NgxUiLoaderService,

  ) {}

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
    this.panelName = 'Dashboard';
    this.href = this.router.url;
    this.userName = localStorage.getItem('userName');
    this.userToken = (localStorage.getItem('userToken'));
    if (!this.userToken) {
      this.router.navigate(['/']);
    }
    const authData = (localStorage.getItem('authData'));
    this.userData = JSON.parse(authData);
    this.userData = this.userData[0].user;
    this.userRole = this.userData.user_role_id;
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  toggle() {
    this.isOpened = !this.isOpened;
  }

  logout() {

    Swal.fire({
      title:'Are you sure?',
      text:'You want to logout?',
      showCancelButton: true,
      confirmButtonColor: '#163862',
      confirmButtonText: 'Confirm',
      cancelButtonColor: '#d33'
    }).then((result) => {
      if (result.value) {

        this.ngxService.start();
        this.service.logout('logout',this.userToken).subscribe(response => {
          this.ngxService.stop();
          this.data = response;
          console.log(this.data);
          if (this.data && this.data.message) {
            Swal.fire({
              html: '<b>You are logged out successfully!</b>',
              showConfirmButton: false,
            });
            localStorage.removeItem('authData');
            localStorage.removeItem('userName');
            localStorage.removeItem('userToken');
            location.reload();
          } else {
            Swal.fire({
              html: '<b>Something went wrong! Try Again</b>',
              showConfirmButton: true,
            });
          }
        }, error => {
          this.ngxService.stop();
          errorValidateAdmin(error.error);
        });

      }
    });
  }

  search(e) {
    this.service.searchKey = e.target.value;
  }
  searchKey() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/ticket'], { queryParams: { search: this.service.searchKey } });
  }
}
