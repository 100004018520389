import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {CommonService} from './common.service';

@Injectable()
export class GeoLocationService {

  coordinates: any;

  constructor(private http: HttpClient, private service: CommonService) { }

  public  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }

  public  getLocation(term: string): Observable<any> {
    return this.http.get(
      'https://maps.google.com/maps/api/geocode/json?address=' +
      term +
      'CA&sensor=false&key=' + this.service.MAP_API
    );
  }
}
