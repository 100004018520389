import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup , Validators, FormControl} from '@angular/forms';
import {Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CommonService} from '../../../_services/common.service';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
// import {errorValidateAdmin} from '../../_helpers/utility';
import Swal from 'sweetalert2';
import {email, MustMatch, passwordPolicy} from '../../../_helpers/utility';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  token: any;
  userEmail: any;
  constructor(
    private fb: FormBuilder,
    @Inject(DOCUMENT) document,
    private service: CommonService,
    private http: HttpClient,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private activeroute: ActivatedRoute,
  ) {
    this.activeroute.queryParams.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
        this.getUserByResetToken(this.token);
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Invalid Token',
          icon: 'error',
          showConfirmButton: true
        });
        this.router.navigate(['']);
      }
    });
  }
  resetPasswordForm: FormGroup;
  data: any;
  authData: any;
  userToken: any;
  errorMessage: any;
  // siteKey = environment.siteKey;
  baseURL;

  invalidUser = 0;
  invalidUserMessage = 'Please enter valid email id';


  createForm() {
    this.resetPasswordForm = this.fb.group({
        email: [{value: this.userEmail, disabled: true}, Validators.required],
        password: ['', Validators.required],
        password_confirmation: ['', Validators.required],
      }, {
        validator: [
          MustMatch('password', 'password_confirmation'),
          passwordPolicy('password'),
          email('email')
        ]
      }
    );
  }

  ngOnInit() {
    this.initProcess();
  }

  initProcess() {
    const ls = localStorage.getItem('authData');
    this.userToken = localStorage.getItem('userToken');
    const data = JSON.parse(ls);
    if (data && data[0].token) {
      this.router.navigate(['/home']);
    }
  }


  resetError() {
    this.errorMessage = '';
  }

  getUserByResetToken(token) {
    const data = { token };
    this.service.postRequest('get-user-by-reset-token', data).subscribe(serviceresponse => {
      const response = serviceresponse;
      if ( response['status'] === 'error' && response['code'] === 400) {
        Swal.fire({
          text: 'Invalid Token',
          showConfirmButton: true
        });
        this.router.navigate(['']);
      } else if ( response['status'] === 'error' && response['code'] === 401) {
        Swal.fire({
          text: 'Token has expired',
          showConfirmButton: true
        });
        this.router.navigate(['']);
      } else if ( response['status'] === 'success') {
        this.userEmail = response['result'].email;
        this.createForm();
      } else {
        Swal.fire({
          text: 'Something went wrong!',
          showConfirmButton: true
        });
        this.router.navigate(['']);
      }
    }, error => {
      Swal.fire({
        text: 'Something went wrong!',
        showConfirmButton: true
      });
      this.router.navigate(['']);
    });
  }


  resetPassword(formData) {

    // tslint:disable-next-line:variable-name
    const _this = this;
    if (this.resetPasswordForm.invalid || !this.token) {
      return;
    }
    formData.email = this.userEmail;
    formData.token = this.token;
    this.ngxService.start();
    this.service.postRequest('reset-password', formData).subscribe(serviceresponse => {
      this.ngxService.stop();
      const response = serviceresponse;
      if ( response['status'] === 'success' && response['code'] === 200) {
        Swal.fire({
          text: 'Password has been reset successfully.',
          showConfirmButton: true
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['']);
          }
        });
      } else if ( response['status'] === 'error' && response['code'] === 404) {
        Swal.fire({
          text: 'Invalid Request data',
          showConfirmButton: true
        });
      } else {
        Swal.fire({
          text: 'Something went wrong! Try Again',
          showConfirmButton: true,
        });
      }
    }, error => {
      this.ngxService.stop();
      Swal.fire({
        text: 'Something went wrong! Try Again',
        icon: 'error',
        showConfirmButton: true,
      });
    });
  }

  warning(type) {
    if (type == 'dob') {
      Swal.fire({
          title: '<strong>Please Note</strong>',
          html:
            'You must be <b>18 years or above</b> to proceed.'
        }
      );
    }
    if (type == 'password') {
      Swal.fire({
          title: '<strong>Password Policy</strong>',
          html: '<hr><ul style="text-align: justify">' +
            '<li>Password length must be equal to or greater than 8</li>' +
            '<li>Password must contain an uppercase character</li>' +
            '<li>Password must contain a lowercase character</li>' +
            '<li>Password must contain a number</li>' +
            '<li>Password must contain a special character</li>' +
            '</ul>'
        }
      );
    }
  }

}
