import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
// @ts-ignore
import { } from '@types/googlemaps';
import {ManageUserComponent} from './pages/users/manage-user/manage-user.component';

@Component({
    selector: 'AutocompleteComponent',
    template: `
      <input title="{{addresstext.value}}" matInput class="input addresstext" id="address" (change) = "checkValue(this.addresstext)"
        type="text"
             placeholder=""
        [(ngModel)]="autocompleteInput"
        #addresstext style="padding: 1px 0; border-bottom: 1px solid #919191; margin-bottom: 5px; margin-top: 15px;"
             autocomplete="off"
        >
    `,
  styles: [`
    .addresstext:focus{
      border-bottom: 2px solid #17A0DB !important;
    }
`]
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
    @Input() adressType: string;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @ViewChild('addresstext', {static: false}) addresstext: any;

    autocompleteInput: string;
    queryWait: boolean;
    autoInput: string;

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.getPlaceAutocomplete();
      // @ts-ignore
      this.autoInput = document.getElementById("address").value;
    }

    checkValue(e){
      // @ts-ignore
      this.autoInput = document.getElementById("address").value;
      // if(e.value == '') {
      //   document.getElementById('address').style.borderBottom = '1px solid red';
      // } else {
      //   document.getElementById('address').style.borderBottom = '1px solid #919191';
      // }
    }

    private getPlaceAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
            {
                componentRestrictions: { country: 'SA' },
                types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
            });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            this.invokeEvent(place);
        });
    }

    invokeEvent(place: Object) {
        this.setAddress.emit(place);
    }

}
