import { Component, OnInit, ViewChild, ViewEncapsulation,  ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import {Router} from '@angular/router';
// import { IAccTooltipRenderEventArgs } from '@syncfusion/ej2-angular-charts';
import { CommonService } from '../../_services/common.service';
import {errorValidateAdmin} from '../../_helpers/utility';
import {NgbModal, NgbSlide} from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
   .carousel-item
  {
    display:block;
  }

  .carousel-control-next-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2381BD41' viewBox='0 0 24 24'%3e%3cpath d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.568 18.005l-1.414-1.415 4.574-4.59-4.574-4.579 1.414-1.416 5.988 5.995-5.988 6.005z'/%3e%3c/svg%3e");
  }
 .carousel-control-prev-icon {
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2317A0DB' viewBox='0 0 24 24'%3e%3cpath d='M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12-5.373-12-12-12-12 5.373-12 12zm7.58 0l5.988-5.995 1.414 1.416-4.574 4.579 4.574 4.59-1.414 1.416-5.988-6.006z'/%3e%3c/svg%3e");
  }
`],
  animations: [
    trigger('simpleTranslation', [
      state('outright', style({ transform: `translateX(100%)` })),
      state('outleft', style({ transform: `translateX(-100%)` })),
      transition('*=>inright', [
        style({transform: `translateX(-100%)`}),
        animate('2s ease-in', style({ transform: `translateX(0)` }))
      ]),
      transition('*=>inleft', [
        style({transform: `translateX(100%)`}),
        animate('2s ease-in', style({ transform: `translateX(0)` }))
      ]),
      transition('*=>outleft', [
        animate('2s ease-in', style({ transform: `translateX(-100%)` }))
      ]),
      transition('*=>outright', [
        animate('2s ease-in', style({ transform: `translateX(100%)` }))
      ]),
    ])
  ]
})
export class HomeComponent implements OnInit {
  @ViewChild('chart', {static: false})
  @ViewChildren(NgbSlide) slides: QueryList<NgbSlide>
  slideControl: any[] = [];
  public chartObj: HomeComponent;
  public piedata: {};
  public datalabel: {};
  public tooltip: {};
  public title: {};
  public legendSettings: {};
  detailDescription = '';
  userToken: any;
  userData: any;
  userRole: any;
  bankWithMostTickets = [];
  closedTickets = 0;
  merchantsRegistered = 0;
  pendingTickets = 0;
  rejectedTickets = 0;
  resolveRequestedTickets = 0;
  resolvedTickets = 0;
  totalTickets = 0;
  performance = 0;
  vendorWithMostTickets = [];
  videoTitle = '';
  videoUrl = '';
  detailTitle = '';
  // images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
  images = [
    {path : 'assets/img/image3.png', title : 'Find out about mada atheer service in a minute!', video : 'assets/videos/video3.mp4'},
    {path : 'assets/img/image1.png', title : 'mada, for everyday', video : 'assets/videos/video1.mp4'},
    {path : 'assets/img/image2.png', title : 'Promotional video for mada service', video : 'assets/videos/video2.mp4'},

  ];

  details = [
    [
      {image_path : 'assets/img/details/atheer.png', title : 'atheer service', description : 'atheer is a value-added service for mada that allows cardholders to make their purchase payments in a safe, easy and fast manner simply by waving the card in front of the PoS terminal. mada cardholders can use this service for any single purchase amount that is less than 100 SAR. Cardholders may be asked to enter their PIN occasionally for extra security.'},
      {image_path : 'assets/img/details/Picture6_0.png', title : 'mada Rewards', description : 'Issuing banks provide many special offers and merchants discounts regularly for mada cardholders with the purpose to incentivise them to pay through mada cards rather than cash in addition to utilize its other associated services.\n' + '\n' + 'mada cardholders may check issuing banks websites to know more on available deals and discounts offered.'},
      {image_path : 'assets/img/details/search-file-icon.png', title : 'PoS reconciliation', description : 'Merchants perform PoS reconciliation in order  to receive funds in their bank account. This process is ideally performed on a daily basis, as per the reconciliation guidelines stipulated by SAMA, to avoid having amounts pending in the system network. Once completed, the acquiring bank will credit the contracted merchants according to the terms and conditions stipulated in the MSA. Deposited payments will cover the transaction totals taking into considerations any reversals, adjustments, and refunds and any applicable merchant discounts. '}
    ],
    [
      {image_path : 'assets/img/details/discover mada.png', title : 'Get to know more about mada', description : 'With the objective to assist business owners to know more about mada and give them a chance to enrich their commercial activities with value-added services through mada electronic payment service, we invite all merchants to obtain marketing materials provided by banks and discover more of the available features.'},
      {image_path : 'assets/img/details/connectivity 1_1.png', title : 'POS choices based on connectivity', description : 'PoS terminals must be connected to a communications network in order to work. There are many different types of terminals in the market, but they can all be classified into three main categories based on connectivity:\n' +
          '\n' +
          'Dial-up: These are phoneline-based terminals and can be found in the majority of small to medium sized businesses. In order to be able to install this type of equipment, merchants will need a landline phone connection on their premises.\n' +
          'IP-based(always on): These terminals utilize broadband connectivity and are attached to a cable modem, DSL modem, or an IP router to process transactions. Unlike dial-up terminals, IP terminals are always faster in processing transactions and at the same time keep merchants’ phone lines free, allowing businesses to run without interruption.\n' +
          'GPRS (mobile): These terminals do not require a traditional phone line or an Internet connection but use regular SIM cards to access a cellular network, such as GPRS or CDMA.These are suitable for merchants who are in need of high speed and mobility.'},
      {image_path : 'assets/img/details/testing 3.png', title : 'How merchants benefit from mada', description : 'mada offers merchants a set of value-added services that helps them grow their business volume and enhance their financial control, such as:\n' +
          '\n' +
          'Benefit from the increased cardholder daily purchase limit from SAR 20,000 to SAR 60,000.\n' +
          'Savings in cash management costs related to cash transactions – less time and effort spent in cash handling, recounting and validating bank notes authenticity, plus the need to always have small change available.\n' +
          'Control risks and human errors related to processes of daily inventories,  revenues, and bookkeeping.\n' +
          'Savings in time and effort – reduce daily cash collections processes and their associated cash transport cost and subsequent deposit with banks.\n' +
          'Efficient financial control- monitor payment inflows coming into the business and the ability to track sales transactions through account statements provided by their bank.\n' +
          'High tendency for sales volumes and values to increase with the growing number of debit cards in the market.\n' +
          'Speed and convenience in completing purchase transactions in connection to around the clock support that ensures service quality  .\n' +
          'Benefit from banks exclusive offers and rewards programs.\n'}
    ],
    [
      {image_path : 'assets/img/details/contract.png', title : 'Merchant Service Agreement (MSA)', description : 'The purpose of this document is to outline the responsibilities of the merchant and their acquiring bank and all the details relating to the POS service being offered in line with mada POS standards defined by the Saudi Central Banks (SAMA).'},
      {image_path : 'assets/img/details/shopping-basket-accept-icon.png', title : 'Merchants', description : 'If you are a merchant or a business owner who is looking for an organized mechanism to manage your financial transactions revenues, then having a PoS terminal at your business gives you that and more. Many SPAN-member banks in KSA provide merchants with banking facilities and services to accept cards payments through their corporate and commercial banking departments.\n' +
          '\n'},
      {image_path : 'assets/img/details/image.jpg', title : 'Merchant Service Providers (MSPs)', description : 'A Merchant Service Provider (MSP) is a company or agent that provides a full suite of merchant services and support within the context of POS installation and on-going maintenance. This agent is nominated by one of the acquiring banks in the KSA where it should be registered and approved by SAMA before being allowed to provide such commercial activity.\n' +
          '\n' +
          'SAMA has issued the MSP Registration Rules and Procedures applicable to MSPs contracted by member banks. This document clearly states the responsibilities of each party in the relationship. These rules also apply to existing authorized POS terminal re-sellers/distributors that provide merchant support.'}
    ],
    [
      {image_path : 'assets/img/details/contract-512.png', title : 'Terminal certifications', description : 'The procedure for the Security Certification of POS terminals sets out the conditions that vendors of POS terminals must fulfil in order to qualify for an approval certificate issued by the General Department of Payment Systems (GDPS) and SAMA.\n' +
          '\n' +
          'Terminal certification in general includes testing the entire cycle from the POS terminal system through acquiring banks to the card issuers. The procedure is applied by the only authorized certification laboratory managed by GDPS. All certifications are handled based on the rules set by EMVCo (Europay, MasterCard, and Visa).\n' +
          '\n' +
          'mada cards work at a wide variety of POS terminals that have been certified by the GDPS Quality Assurance Team. To find out more, please contact certification@sama.gov.sa'},
      {image_path : 'assets/img/details/column-chart-icon.png', title : 'Organize your financial expenditures', description : 'All mada card transactions are recorded on current account statements, on-line records, or on mini-statements from ATMs. These detail the merchant name, transaction date, and value. In addition, for every transaction, the POS terminal generates a cardholder receipt that can be kept as a record. Undoubtedly, such receipts give cardholders further control over personal expenditures and  better management of financial affairs.'},
      {image_path : 'assets/img/details/ico-offline-72a31c015904623e32d5c14f336912cb_0.png', title : 'Discover mada', description : 'mada payment service  paves the way for cardholders to enjoy  a wide range of services that  ensure speed, convenience, and security when making purchases at POS terminal  in retail outlets\n' +
          '\n' +
          'Some of the added value features that mada payment card offers:\n' +
          '\n' +
          'Ease of use and speed in completing purchase transactions with the network capacity upgrade.\n' +
          'Increased daily card purchase limit from SAR 20,000 to SAR 60,000.\n' +
          'Purchase with Cash back service - Limit the need to look for ATMs to withdraw cash.\n' +
          'Reduces the need to carry large amounts of cash, making the shopping experience safer and more convenient.\n' +
          'Instant SMS notifications to cardholders on any cash withdrawals or POS transactions.\n' +
          'Access to attractive promotional offers from their bank.\n' +
          'Fund accessibility through over 160,000  PoS terminals and over 16,000 ATMs across the kingdom, in addition to millions around the world.'}
    ],
    [
      {image_path : 'assets/img/details/ico-personal-fb2c17105fdf21dd319fdb3a6a6dbdc5.png', title : 'mada Prepaid Cards', description : 'Prepaid cards are linked to prepaid records and are intended to be used on the principle of “light account" structures in accordance with the Regulatory Rules for Prepaid Payment Services in the Kingdom of Saudi Arabia issued in 2012. These records offer limited functionality compared to standard bank accounts, providing only transactional services at POS terminals and ATMs, bill payment through SADAD, and remittance services.\n' +
          '\n' +
          'Based on the type of program and the product specifications in general, these electronic records are used to provide basic payment services, such as ATM cash withdrawals, POS purchases, and SADAD bill payments, and they offer SMS/online/ATM-based record maintenance, that is, a transaction history.\n' +
          '\n' +
          'Standard banking services are not provided through these records. Nor can they be used to initiate record-to-record transfers unless the beneficiary is a SADAD corporate biller and/or international remittance beneficiary. Furthermore, prepaid records do not allow for automated or teller-less cash funding (e.g., ATM-based deposits). All prepaid cards are funded by verified and approved funding sources. However, this restriction can be lifted as certain prepaid programs may choose to allow ATM-based cash funding.'}
    ],
  ];

  constructor(
    private router: Router,
    private service: CommonService,
    private modalService: NgbModal,
    private ngxService: NgxUiLoaderService

  ) { }

  ngOnInit() {
    this.initProcess();
  }

  initProcess() {
    this.userToken = (localStorage.getItem('userToken'));
    const authData = (localStorage.getItem('authData'));
    this.userData = JSON.parse(authData);
    this.userData = this.userData[0].user;
    this.userRole = this.userData.user_role_id;
    if (this.userRole === 4) {
      this.title = 'Gallery';
    } else {
      this.title = 'Ticket Analysis';
    }
    if (!this.userToken) {
      this.router.navigate(['/']);
    }
    this.dashboardDetails();
  }

  viewContent(content, elementdata: any) {
    this.videoTitle = elementdata.title;
    this.videoUrl = elementdata.video;
    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', animation: true, size: 'lg', windowClass : 'myCustomModalClass', centered: true, scrollable: true}).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  viewDetail(content, elementdata: any) {
    console.log(elementdata);
    this.detailTitle = elementdata.title;
    this.detailDescription = elementdata.description;
    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', animation: true, size: 'lg', windowClass : 'myCustomModalClass', centered: true, scrollable: true}).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  dashboardDetails() {
    this.ngxService.start();
    this.service.postRequestCreator('get-dashboard-details', null, this.userToken).subscribe((serviceresponse) => {
      this.ngxService.stop();
      const response = serviceresponse;
      if (response['code'] === 200) {
        this.merchantsRegistered = response['result'].merchantsRegistered;
        this.totalTickets = response['result'].totalTickets;
        this.performance = response['result'].performance;
        this.bankWithMostTickets = response['result'].bankWithMostTickets;
        this.vendorWithMostTickets = response['result'].vendorWithMostTickets;
        this.pendingTickets = response['result'].pendingTickets;
        this.resolveRequestedTickets = response['result'].resolveRequestedTickets;
        this.resolvedTickets = response['result'].resolvedTickets;
        this.rejectedTickets = response['result'].rejectedTickets;
        this.closedTickets = response['result'].closedTickets;
      }
    }, error => {
      this.ngxService.stop();
      errorValidateAdmin(error);
    });
  }

  onSlideClicked(value: any) {
    const position = value.activeId.charAt((value.activeId.length) - 1);
  }

  // createGraph() {
  //   this.datalabel = { visible: true };
  //   this.tooltip = {enable: true};
  //   this.legendSettings = {
  //     visible: true
  //   };
  //   this.piedata = [
  //     { 'x': 'Chrome', y: 37 }, { 'x': 'UC Browser', y: 17 },
  //     { 'x': 'iPhone', y: 19 }, { 'x': 'Others', y: 4 }, { 'x': 'Opera', y: 11 }
  //   ];
  // }


  // print() {
  //   this.chartObj.print();
  // }
  onNavigate(href) {
    window.open(href, '_blank');
  }


  onSlide(event) {
    this.slides.forEach((x, index) => {
      if (x.id == event.current) {
        this.slideControl[index] = 'in' + event.direction;
      }
      if (x.id == event.prev) {
        this.slideControl[index] = 'out' + event.direction;
      }
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    setTimeout(() => {
      this.slides.forEach((x, index) => {

        this.slideControl[index] = index ? 'outleft' : 'inleft';
      });
    });
  }

  // public tooltipRender(args: IAccTooltipRenderEventArgs): void {
  //   const value  = args.point.y / args.series.sumOfPoints * 100;
  //   // @ts-ignore
  //   args.text = args.point.x + ' : ' + Math.ceil(value) + '' + '%';
  // }
}
