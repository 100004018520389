import {Component, OnInit, ViewChild, ViewEncapsulation,  ViewChildren, QueryList, AfterViewInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import {Router} from '@angular/router';
import { CommonService } from '../../_services/common.service';
import {NgbModal, NgbSlide} from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {errorValidateAdmin} from "../../_helpers/utility";

declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);
require('highcharts/modules/variable-pie')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  userToken: any;
  userData: any;
  userRole: any;
  bankTitle: any;
  bankCategories: any;
  bankSeries: any;
  vendorTitle: any;
  vendorCategories: any;
  vendorSeries: any;
  issuesTitle: any;
  issuesSeries: any;
  timeTitle: any;
  timeSeries: any;
  public bankOptions: any;
  public vendorOptions: any;
  public issuesOptions: any;
  public timeOptions: any;

  constructor(
    private router: Router,
    private service: CommonService,
    private modalService: NgbModal,
    private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit() {
    this.initProcess();
  }
  initProcess() {
    this.userToken = (localStorage.getItem('userToken'));
    const authData = (localStorage.getItem('authData'));
    this.userData = JSON.parse(authData);
    this.userData = this.userData[0].user;
    this.userRole = this.userData.user_role_id;
    if (!this.userToken) {
      this.router.navigate(['/']);
    }
    this.getReportGraphsData();
  }

  getReportGraphsData() {

    this.ngxService.start();
    this.service.postRequestCreator('get-reports-graphs', null, this.userToken).subscribe((serviceresponse) => {
      this.ngxService.stop();
      const response = serviceresponse;
      if (response['code'] === 200) {
        this.bankTitle = response['result'].bankGraph.title;
        this.bankCategories = response['result'].bankGraph.bankCategories;
        this.bankSeries = response['result'].bankGraph.bankSeries;
        this.vendorTitle = response['result'].vendorGraph.title;
        this.vendorCategories = response['result'].vendorGraph.vendorCategories;
        this.vendorSeries = response['result'].vendorGraph.vendorSeries;
        this.issuesTitle = response['result'].issueGraph.title;
        this.issuesSeries = response['result'].issueGraph.issuesSeries;
        this.timeTitle = response['result'].timeGraph.title;
        this.timeSeries = response['result'].timeGraph.timeSeries;
        if (this.userRole == 1 || this.userRole == 3) {
          this.createBankGraph();
        }
        if (this.userRole == 1 || this.userRole == 2) {

          this.createVendorGraph();
        }
      }
      this.createIssuesGraph();
      this.createAvgTimeGraph();
    }, error => {
      this.ngxService.stop();
      errorValidateAdmin(error);
    });
  }

  createBankGraph() {
    // for (let i = 0; i < this.bankSeries.length; i++){
    //   this.bankSeries[i].data = Object.values(this.bankSeries[i].data);
    // }
    this.bankOptions = {
      chart: {
        type: 'column',
        backgroundColor: 'rgba(23, 160, 219, 0.05)'
      },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
              ]
            },
          },
          enabled: true,
        },
        navigation: {
          buttonOptions: {
            align: 'right',
            verticalAlign: 'top',
            y: 0,
            x: -35,
            theme: {
              fill: 'rgba(23, 160, 219, 0)'
            }
          }
        },
        title: {
          text: ''
        },

        legend: {
          enabled: false,
          align: 'right',
          verticalAlign: 'middle'
        },

        credits: {
          enabled: false
        },
      xAxis: {
        categories: this.bankCategories
      },

      yAxis: {
        min: 0,
        title: {
          text: 'Number of Tickets'
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || 'gray'
          }
        }
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      },
      series: this.bankSeries
    };

    Highcharts.chart('bank-container', this.bankOptions).reflow();
  }

  createVendorGraph() {
    // for (let i = 0; i < this.vendorSeries.length; i++) {
    //   this.vendorSeries[i].data = Object.values(this.vendorSeries[i].data);
    // }

    // this.vendorOptions = {
    //   chart: {
    //     type: 'column',
    //     style: {
    //       zoom: 0.99
    //     }
    //   },
    //
    //   exporting: {
    //     buttons: {
    //       contextButton: {
    //         menuItems: [
    //           'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
    //         ]
    //       },
    //     },
    //     enabled: true,
    //   },
    //   navigation: {
    //     buttonOptions: {
    //       align: 'right',
    //       verticalAlign: 'top',
    //       y: 0,
    //       x: -35
    //     }
    //   },
    //
    //   title: {
    //     text: ''
    //   },
    //
    //   legend: {
    //     enabled: false,
    //     align: 'right',
    //     verticalAlign: 'middle'
    //   },
    //
    //   credits: {
    //     enabled: false
    //   },
    //
    //   xAxis: {
    //     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    //   },
    //
    //   yAxis: {
    //     allowDecimals: false,
    //     min: 0,
    //     title: {
    //       text: 'Number of Tickets'
    //     }
    //   },
    //
    //   tooltip: {
    //     formatter: function () {
    //       return '<b>' + this.series.userOptions.stack + '</b><br/>' +
    //         this.series.name + ': ' + this.y + '<br/>' +
    //         'Total: ' + this.point.stackTotal;
    //
    //     }
    //   },
    //
    //   plotOptions: {
    //     column: {
    //       stacking: 'normal'
    //     }
    //   },
    //
    //   series: this.vendorSeries ? this.vendorSeries : []
    // };

    this.vendorOptions = {
        chart: {
          type: 'column',
          backgroundColor: 'rgba(23, 160, 219, 0.05)'
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
              ]
            },
          },
          enabled: true,
        },
        navigation: {
          buttonOptions: {
            align: 'right',
            verticalAlign: 'top',
            y: 0,
            x: -35,
            theme: {
              fill: 'rgba(23, 160, 219, 0)'
            }
          }
        },
        title: {
          text: ''
        },

        legend: {
          enabled: false,
          align: 'right',
          verticalAlign: 'middle'
        },

        credits: {
          enabled: false
        },
        xAxis: {
          categories: this.vendorCategories
        },

        yAxis: {
          min: 0,
          title: {
            text: 'Number of Tickets'
          },
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: 'bold',
              color: ( // theme
                Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color
              ) || 'gray'
            }
          }
        },
        tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false
            }
          }
        },
        series: this.vendorSeries
      };

    Highcharts.chart('vendor-container', this.vendorOptions).reflow();
  }

  createIssuesGraph() {

    this.issuesOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        backgroundColor: 'rgba(23, 160, 219, 0.05)'
      },

      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
            ]
          },
        },
        enabled: true,
      },
      navigation: {
        buttonOptions: {
          align: 'right',
          verticalAlign: 'top',
          y: 0,
          x: -35,
          theme: {
            fill: 'rgba(23, 160, 219, 0)'
          }
        }
      },

      title: {
        text: ''
      },

      // legend: {
      //   enabled: false,
      //   align: 'right',
      //   verticalAlign: 'middle'
      // },

      credits: {
        enabled: false
      },

      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}'
          },
          showInLegend: false
        }
      },
      series: [{
        name: 'Issues',
        colorByPoint: false,
        data: this.issuesSeries
      }]
    };
    Highcharts.chart('issues-container', this.issuesOptions).reflow();
  }

  createAvgTimeGraph() {
    this.timeOptions = {
      chart: {
        backgroundColor: 'rgba(23, 160, 219, 0.05)'
      },
      title: {
        text: ''
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
            ]
          },
        },
        enabled: true,
      },
      navigation: {
        buttonOptions: {
          align: 'right',
          verticalAlign: 'top',
          y: 0,
          x: -35,
          theme: {
            fill: 'rgba(23, 160, 219, 0)'
          }
        }
      },

      yAxis: {
        title: {
          text: 'Number of Days'
        }
      },

      xAxis: {
        accessibility: {
          rangeDescription: 'Months: Jan to Dec'
        },
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      tooltip: {
        // headerFormat: '<b>{point.x}</b><br/>',
        // pointFormat: 'Days: {point.y}'
        formatter: function() {
          const isFloat = Number(this.y) === this.y && this.y % 1 !== 0;
          if (isFloat) {
            const n = Math.abs(this.y); // Change to positive
            // const decimal = Math.ceil((n - Math.floor(n)) * 10);
            const decimal = ((n - Math.floor(n)));
            const hours = parseFloat((decimal * 8).toString()).toFixed(2);
            return '<b>' + this.x + '</b><br/>' +
              // tslint:disable-next-line:radix
              this.series.name + ': ' + parseInt(this.y) + 'D ' + Math.ceil(parseFloat(hours)) + 'H' + '<br/>';
          } else {
            return '<b>' + this.x + '</b><br/>' +
              this.series.name + ': ' + (this.y) + 'D' + '<br/>';
          }

        }
      },
      // legend: {
      //   layout: 'vertical',
      //   align: 'right',
      //   verticalAlign: 'middle'
      // },

      credits: {
        enabled: false
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          }
        }
      },

      series: this.timeSeries,

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }

    };
    Highcharts.chart('avg-time-container', this.timeOptions).reflow();
  }

}
