import {Component, Inject, OnInit, ViewChild, AfterViewInit, ElementRef} from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DOCUMENT} from '@angular/common';
import {environment} from '../../../environments/environment.prod';
import { tap } from 'rxjs/operators';
import {CommonService} from '../../_services/common.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';
import {errorValidateAdmin} from '../../_helpers/utility';
import { DateAdapter } from '@angular/material';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {tick} from '@angular/core/testing';
import {Track} from 'ngx-audio-player';
import {SelectionModel} from '@angular/cdk/collections';
import { AudioRecordingService } from '../../_services/audio-recording.service';
import { DomSanitizer } from '@angular/platform-browser';
import {PlayerFile, PlayerConfig, PlayerTheme, PlayerThemeDark, PlayerThemeLight, PlayerStreamState} from 'gs-player';
import {RecordRtcComponent} from '../record-rtc/record-rtc.component';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})
export class TicketComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    @Inject(DOCUMENT) document,
    private service: CommonService,
    private http: HttpClient,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private dateAdapter: DateAdapter<Date>,
    private modalService: NgbModal,
    private audioRecordingService: AudioRecordingService,
    private sanitizer: DomSanitizer,
    private activeroute: ActivatedRoute
  ) {

    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isRecording = false;
    });

    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.recordedTime = time;
    });

    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      this.blobFile = data.blob;
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
    });

    this.dateAdapter.setLocale('en-GB');
    this.createForm();
  }
  incidentAudioUrl = '';
  resolvedAudioUrl = '';
  // Array of PlayerFiles (file url, name, artist and album)
  playerStreamState: PlayerStreamState = {
      playing : false,
    readableCurrentTime : '',
    readableDuration: '',
    duration : undefined,
    currentTime : undefined,
    canplay : undefined,
    error : undefined,
  };
  incidentFiles = [];
  resolvedFiles = [];
  actionFiles = [];

  // Player color theme (one of PlayerThemeDark or PlayerThemeLight)
  // You can also crete your own color theme
  playerTheme: PlayerTheme = PlayerThemeDark;

  audioProof = 1;
  videoProof = 1;
  proofTab = 0;

  userToken: any;
  userData: any;
  userRole: any;

  isRecording = false;
  isLoading = false;
  recordedTime;
  blobUrl;
  blobFile;

  initStatus = '';
  route: any;
  data: any;
  res: any;
  from: number;
  total: number;
  // tslint:disable-next-line:max-line-length
  displayedColumns: string[] = ['name', 'pos_terminal_id', 'created_at', 'status', 'action'];
  selection = new SelectionModel<Element>(true, []);
  dataSource: any;
  issuesList: any;
  banksList: any;
  vendorsList: any;
  regionsList: any;
  citiesList: any;
  ngFilter: FormGroup;
  manageComplaintFormGroup: FormGroup;
  toDay = new Date();
  complaintData: any;
  historyData: any;
  // tslint:disable-next-line:variable-name
  image_path = './assets/img/no-image.jpg';
  // tslint:disable-next-line:variable-name
  audio_path: any;
  // tslint:disable-next-line:variable-name
  video_path: any;
  selectedImage: any;
  selectedAudio: any;
  selectedVideo: any;
  videoUrl: any;
  videoLoaded = false;
  loadingState = false;
  audSrc = 'assets/audio/sample.mp3';

  actionText: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('incidentVideoElement', {static: false}) public incidentVideoElement: ElementRef;
  @ViewChild('resolvedVideoElement', {static: false}) public resolvedVideoElement: ElementRef;
  @ViewChild('actionVideoElement', {static: false}) public actionVideoElement: ElementRef;
  @ViewChild('figAudio', {static: false}) figAudio;
  @ViewChild('actionAudio', {static: false}) actionAudio;

  incidentVideoUrl: any;
  resolvedVideoUrl: any;
  actionVideoUrl: any;

  msbapAudioTitle = 'No File Selected';
  msbapAudioUrl = '';
  msaapDisplayTitle = false;
  msaapDisplayPlayList = false;
  msaapPageSizeOptions = [1];
  msaapDisplayVolumeControls = true;
  msaapDisablePositionSlider = false;

  msbapResolvedAudioTitle = 'No File Selected';
  msbapResolvedAudioUrl = '';

  msaapResolvedDisplayTitle = false;
  msaapResolvedDisplayPlayList = false;
  msaapResolvedPageSizeOptions = [1];
  msaapResolvedDisplayVolumeControls = true;
  msaapResolvedDisablePositionSlider = false;

  msbapActionAudioTitle = 'No File Selected';
  msbapActionAudioUrl = '';

  msaapActionDisplayTitle = true;
  msaapActionDisplayPlayList = true;
  msaapActionPageSizeOptions = [1];
  msaapActionDisplayVolumeControls = true;
  msaapActionDisablePositionSlider = false;

  msaapPlaylist: Track[] = [
    {
      title: this.msbapAudioTitle,
      link: this.msbapAudioUrl
    }
  ];

  msaapResolvedPlaylist: Track[] = [
    {
      title: this.msbapResolvedAudioTitle,
      link: this.msbapResolvedAudioUrl
    }
  ];

  msaapActionPlaylist: Track[] = [
    {
      title: this.msbapActionAudioTitle,
      link: this.msbapActionAudioUrl
    }
  ];
  isRejectAction = false;

  createForm() {
    this.ngFilter = this.fb.group({
        start_date: [''],
        end_date: [''],
        bank_id: [''],
        vendor_id: [''],
        region_id: [''],
        city: [''],
        issue_id: [''],
        status: [''],
        terminal_ticket: [''],
      }
    );
  }

  onSelectImage(e) {
    const files = e.target.files[0];

    if (!files) {
      this.selectedImage = null;
    } else {
      this.selectedImage = files;
    }

    if (e.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        this.image_path = event.target.result;
      };
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  resetProof() {
    this.image_path = './assets/img/no-image.jpg';
    this.videoUrl = null;
    this.selectedVideo = null;
    this.selectedImage = null;
    this.selectedAudio = null;
    this.videoLoaded = false;
    this.msbapActionAudioTitle = 'No File Selected';
    this.msbapActionAudioUrl = '';
    this.msaapActionPlaylist = [
      {
        title: this.msbapActionAudioTitle,
        link: this.msbapActionAudioUrl
      }
    ];
    this.service.actionVideoBlob = null;
  }

  public readUrl(event) {
    this.loadingState = true;
    this.videoLoaded = false;
    if (event.target.files && event.target.files[0]) {
      this.selectedVideo = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (data: any) => {
        this.playVideo(data.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      this.selectedVideo = null;
    }
  }

  public playVideo(url) {
    this.loadingState = false;
    this.videoLoaded = true;
    this.videoUrl = url;
    if (this.actionVideoElement !== undefined) {
      this.actionVideoElement.nativeElement.load();
    }
  }

  startRecording() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
    }
  }

  clearRecordedData() {
    this.blobUrl = null;
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.abortRecording();
  }

  public changeAudioProof(event) {
    this.audioProof = event.value;
  }
  public changeVideoProof(event) {
    this.videoProof = event.value;
    this.selectedVideo = null;
    this.service.actionVideoBlob = null;
  }
  audFileSelected(event: any) {
    const files = event.target.files[0];
    if (!files) {
      this.selectedAudio = null;
    } else {
      this.selectedAudio = files;
    }
    if (event.target.files && event.target.files[0]) {
      const audSrc = URL.createObjectURL(event.target.files[0]);
      console.log(audSrc);
      this.msbapAudioTitle = event.target.files[0].name;
      this.msaapActionPlaylist[0].link = this.audSrc;
      this.msaapActionPlaylist[0].title = event.target.files[0].name;
    }
  }

  viewContent(content, ticketID) {
    this.ngxService.start();
    const filter = {
      search: {complaint_id : ticketID}
    };

    this.service.postRequestCreator('get-complaint-details', filter, this.userToken).subscribe(serviceresponse => {
        this.ngxService.stop();
        // @ts-ignore
        if (serviceresponse.code === 200) {
          // @ts-ignore
          this.complaintData = serviceresponse.result.data[0];
          this.historyData = serviceresponse['result'].history;

          this.incidentVideoUrl = this.complaintData.incident_video_path;
          this.resolvedVideoUrl = this.complaintData.resolved_video_path;

          this.msaapPlaylist[0].link = this.complaintData.incident_audio_path;
          this.msaapPlaylist[0].title = this.complaintData.incident_audio_path;
          this.incidentAudioUrl = this.complaintData.incident_audio_path;
          this.msaapResolvedPlaylist[0].link = this.complaintData.resolved_audio_path;
          this.msaapResolvedPlaylist[0].title = this.complaintData.resolved_audio_path;
          this.resolvedAudioUrl = this.complaintData.resolved_audio_path;

          this.incidentFiles =  [
            {
              url: this.incidentAudioUrl,
              name: '',
            }
          ];

          this.resolvedFiles =  [
            {
              url: this.resolvedAudioUrl,
              name: ''
            }
          ];

          this.getProofTab();
          // @ts-ignore
          // tslint:disable-next-line:max-line-length
          this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', animation: true, size: 'xl', windowClass : 'myCustomModalClass', centered: true, scrollable: true}).result.then((result) => {
            // this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        }
      }
      , error => {
        this.ngxService.stop();
        errorValidateAdmin(error);
      });

  }

  getProofTab() {
    const status = (this.complaintData.status != undefined && this.complaintData.status) ? this.complaintData.status : 'Pending';
    if (status === 'Pending' || status === 'Rejected') {
      this.proofTab = 0;
    } else {
      this.proofTab = 1;
    }
  }

  viewAction(content, actionText) {
    this.selectedAudio = null;
    this.blobFile = null;
    this.blobUrl = null;
    this.audioProof = 1;
    this.manageComplaintFormGroup = this.fb.group({
      image: [''],
      video: [''],
      audio: [''],
      comment: ['']
    });

    let controlsConfig = {};
    this.actionText = actionText;
    if (this.actionText === 'Reject') {
      controlsConfig = {
        image: [''],
        video: [''],
        audio: [''],
        comment: ['', Validators.required]
      };
      this.isRejectAction = true;
    } else {
      controlsConfig = {
        image: [''],
        video: [''],
        audio: [''],
        comment: ['']
      };
      this.isRejectAction = false;
    }

    this.manageComplaintFormGroup = this.fb.group(controlsConfig);
    // tslint:disable-next-line:max-line-length
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass : 'myCustomModalClass', centered: true, scrollable: true}).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.resetProof();
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  takeAction(actionText) {
    this.manageComplaintFormGroup = this.fb.group({
      image: [''],
      video: [''],
      audio: [''],
      comment: ['']
    });
    this.actionText = actionText;
    this.saveComplaintAction(this.manageComplaintFormGroup);
  }

  ngOnInit() {
    this.userToken = (localStorage.getItem('userToken'));
    if (!this.userToken) {
      this.router.navigate(['/']);
    }
    const authData = (localStorage.getItem('authData'));
    this.userData = JSON.parse(authData);
    this.userData = this.userData[0].user;
    this.userRole = this.userData.user_role_id;

    // tslint:disable-next-line:variable-name
    const _this = this;

    if (this.userRole === 3) {
      this.ngFilter.value.status = 'Pending';
      this.initStatus = 'Pending';
    } else if (this.userRole === 2) {
      this.ngFilter.value.status = 'Resolve-requested';
      this.initStatus = 'Resolve-requested';
    }

    this.activeroute.queryParams.subscribe(params => {
      if (params['search']) {
        this.service.searchKey = params['search'];
        this.ngFilter.value.terminal_ticket = params['search'];
      }
    });

    this.getComplaints();
    this.getIssuesList();
    this.getBanksList();
    this.getVendorsList();
    this.getRegionsList();
    this.getCitiesList();
  }

  getComplaints() {

    this.ngxService.start();
    this.data = {
      page: this.paginator.pageIndex + 1,
      search: this.ngFilter.value,
      sort: this.sort.active,
      order: this.sort.direction,
      recordsPerPage: this.paginator.pageSize
    };

    this.service.postRequestCreator('get-complaints', this.data, this.userToken).subscribe(response => {
        this.ngxService.stop();
        this.data = response;
        this.dataSource = new MatTableDataSource(this.data.result.data.data);
        const perPage = (this.data.result.perPage);
        const currentPage = this.data.result.currentPage;
        this.from = this.data.result.data.from;
        this.total = this.data.result.data.total;
        this.dataSource.sort = this.sort;
      }
      , error => {
        this.ngxService.stop();
        errorValidateAdmin(error);
      });
  }

  getIssuesList() {
    this.service.getRequest('get-issues-list').subscribe( (serviceresponse: any) => {
        if (serviceresponse.code === 200) {
          this.issuesList = serviceresponse.result ? serviceresponse.result : [];
        }
      },
      error => {
        errorValidateAdmin(error);
      }
    );
  }

  reset() {
    this.paginator.pageIndex = 0;
    this.service.searchKey = '';
    this.activeroute.queryParams.subscribe(params => {
      this.router.navigate(['/ticket'], { queryParams: {} });
    });
    this.getComplaints();
  }

  filter(filterData) {
    if (filterData.start_date) {
      filterData.start_date = this.formatDate(filterData.start_date);
    }
    if (filterData.end_date) {
      filterData.end_date = this.formatDate(filterData.end_date);
    }
    this.getComplaints();
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    const dateFormat = [year, month, day].join('-');
    return dateFormat;
  }

  getRegionsList() {
    this.service.getRequest('get-regions-list').subscribe( (serviceresponse: any) => {
        if (serviceresponse.code === 200) {
          this.regionsList = serviceresponse.result ? serviceresponse.result : [];
        }
      },
      error => {
        errorValidateAdmin(error);
      }
    );
  }

  getBanksList() {
    this.service.getRequest('get-banks-list').subscribe( (serviceresponse: any) => {
        if (serviceresponse.code === 200) {
          this.banksList = serviceresponse.result ? serviceresponse.result : [];
        }
      },
      error => {
        errorValidateAdmin(error);
      }
    );
  }

  getVendorsList() {
    this.service.getRequest('get-vendors-list').subscribe( (serviceresponse: any) => {
        if (serviceresponse.code === 200) {
          this.vendorsList = serviceresponse.result ? serviceresponse.result : [];
        }
      },
      error => {
        errorValidateAdmin(error);
      }
    );
  }

  getCitiesList() {
    this.service.getRequest('get-cities-list').subscribe( (serviceresponse: any) => {
        if (serviceresponse.code === 200) {
          this.citiesList = serviceresponse.result ? serviceresponse.result : [];
        }
      },
      error => {
        errorValidateAdmin(error);
      }
    );
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.getComplaints())).subscribe();
    this.sort.sortChange.pipe(tap(() => this.getComplaints())).subscribe();
  }

  displayMessage(key, value) {
    if (key == 'Rating') {
      Swal.fire(key.toUpperCase(), '<hr>' + ' <div style="text-align: center">' + value + '</div>');
    } else {
      Swal.fire(key.toUpperCase(), '<hr>' + ' <div style="text-align: justify">' + value + ' </div>');
    }
  }

  @ViewChild(RecordRtcComponent, {static: false}) child;

  saveComplaintAction(formData) {
    if (this.manageComplaintFormGroup.invalid) {
      // Swal.fire('Oops...', 'Please Fill the form correctly!', 'error');
      return;
    }
    // tslint:disable-next-line:radix

    this.ngxService.start();

    this.route = 'update-complaint-status';

    const myFormData = new FormData();
    let action = '';
    if (this.actionText == 'Resolve' && this.userRole == 3) {
        action = 'Resolve-requested';
    } else if (this.actionText == 'Resolve' && this.userRole == 2) {
        action = 'Resolved';
    } else if (this.actionText == 'Reject') {
        action = 'Rejected';
    } else if (this.actionText == 'Close') {
        action = 'Closed';
    }

    myFormData.append('complaint_id', this.complaintData.id);
    myFormData.append('action', action);
    myFormData.append('comment', formData.comment);
    myFormData.append('image', this.selectedImage);
    if (this.audioProof == 1) {
      myFormData.append('audio', this.selectedAudio);
    } else {
      myFormData.append('audio', this.blobFile);
    }

    if (this.videoProof == 1) {
      myFormData.append('video', this.selectedVideo);
    } else {
      myFormData.append('video', this.service.actionVideoBlob);
    }

    this.isLoading = true;
    this.service.postRequestCreatorWithFile(this.route, myFormData, this.userToken).subscribe(
      (serviceresponse: any) => {
        this.isLoading = false;
        this.ngxService.stop();
        if (serviceresponse.code === 200 || serviceresponse.code === 202) {
          Swal.fire({
            title: serviceresponse.message,
            showConfirmButton: true
          });

          this.modalService.dismissAll('action');
          this.getComplaints();
        } else if (serviceresponse.code === 400) {
          Swal.fire('Oops...', 'Please Fill the form correctly!', 'error');
        } else {
          Swal.fire('Oops...', 'Something went wrong!', 'error');
        }
      },
      error => {
        this.isLoading = false;
        this.ngxService.stop();
        errorValidateAdmin(error);
      }
    );
  }

  exportReport(type) {
    let a;
    const token = localStorage.getItem('userToken');

    a = document.getElementById('export' + type);
    a.href = environment.apiBaseUrl + '/ticket-detail-export/' + type + '/' + token
      + '?start_date=' + ((this.ngFilter.value.start_date) ? btoa(this.formatDate(this.ngFilter.value.start_date)) : btoa(null))
      + '&end_date=' + ((this.ngFilter.value.end_date) ? btoa(this.formatDate(this.ngFilter.value.end_date)) : btoa(null))
      + '&bank_id=' + ((this.ngFilter.value.bank_id) ? btoa(this.ngFilter.value.bank_id) : btoa(null))
      + '&vendor_id=' + ((this.ngFilter.value.vendor_id) ? btoa(this.ngFilter.value.vendor_id) : btoa(null))
      + '&region_id=' + ((this.ngFilter.value.region_id) ? btoa(this.ngFilter.value.region_id) : btoa(null))
      + '&city=' + ((this.ngFilter.value.city) ? btoa(this.ngFilter.value.city) : btoa(null))
      + '&issue_id=' + ((this.ngFilter.value.issue_id) ? btoa(this.ngFilter.value.issue_id) : btoa(null))
      + '&status=' + ((this.ngFilter.value.status) ? btoa(this.ngFilter.value.status) : btoa(null))
      + '&terminal_ticket=' + ((this.ngFilter.value.terminal_ticket) ? btoa(this.ngFilter.value.terminal_ticket) : btoa(null))
        ;
    document.getElementById('export' + type).click();
  }

}

export interface Elements {
  serial: string;
  name: string;
  pos_terminal_id: string;
  created_at: string;
  status: string;
  action: string;
}
// @ts-ignore
export let JQuery: any = window.jQuery;
