import Swal from 'sweetalert2';
import {FormGroup} from '@angular/forms';


export function errorValidateAdmin(err) {
  if (err.code === 401 || err.error.message === 'Unauthenticated.' || err.status === 401) {
    localStorage.removeItem('authData');
    localStorage.removeItem('userName');
    localStorage.removeItem('userToken');
    // Swal.fire({
    //   title: 'Error!',
    //   text: 'Session Expired',
    //   icon: 'error',
    //   showConfirmButton: false,
    //   timer: 1500
    // });
    location.reload();
    return;
  } else if (err.code === 500) {
    Swal.fire({
      title: 'Error!',
      text: 'Something went wrong!',
      icon: 'error',
      showConfirmButton: false,
      timer: 1500
    });
    return;
  }
}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
export function date(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    console.log('date', control);
    const currentDate = new Date();
    if (control.value == '' || control.value == null) {
      control.setErrors({ mustValid: true });
    } else {
      control.setErrors(null);
    }
  };
}

export function passwordPolicy(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const upperCaseCharacters = /[A-Z]+/g;
    const lowerCaseCharacters = /[a-z]+/g;
    const numCharacters = /[0-9]+/g;
    const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (upperCaseCharacters.test(control.value) === false) {
      control.setErrors({ mustUpp: true });
    } else if (lowerCaseCharacters.test(control.value) === false) {
      control.setErrors({ mustLow: true });
    } else if (numCharacters.test(control.value) === false) {
      control.setErrors({ mustNum: true });
    } else if (specialCharacters.test(control.value) === false) {
      control.setErrors({ mustSpc: true });
    } else if (control.value.length < 8) {
      control.setErrors({ mustLen: true });
    } else {
      control.setErrors(null);
    }
  };
}

export function email(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const checkValue = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (checkValue.test(control.value) === false) {
      control.setErrors({ mustEmail: true });
    } else {
      control.setErrors(null);
    }
  };
}

export function number(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    if (control.value.toString().length >= 5) {
      control.setErrors(null);
    } else {
      control.setErrors({ mustMob: true });
    }
  };
}

export function diff_years(dt1, dt2) {
  if (dt1 === '') {
    return;
  }
  let diff = ( dt2.getTime() - dt1.getTime()) / 1000;
  diff /= (60 * 60 * 24);
  return Math.abs(Math.round(diff / 365.25));
}

export function formatDate(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) { month = '0' + month; }
  if (day.length < 2) { day = '0' + day; }
  return [year, month, day].join('-');
}


export function minValue(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];

    if(!control.value){
      control.setErrors({required: true});
    }

    const matchingControl = formGroup.controls[matchingControlName];
    if (parseFloat(control.value) > parseFloat(matchingControl.value)) {
      control.setErrors({minValue: true});
    } else if (parseFloat(control.value) < parseFloat(matchingControl.value)) {
      control.setErrors(null);
    }
  };
}


export function maxValue(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if(!matchingControl.value){
      matchingControl.setErrors({required: true});
    }

    if (parseFloat(control.value) > parseFloat(matchingControl.value)) {
      matchingControl.setErrors({maxValue: true});
    } else if (parseFloat(control.value) < parseFloat(matchingControl.value)) {
      matchingControl.setErrors(null);
    }
  };
}
