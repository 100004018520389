import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import {MatFormFieldModule, MatInputModule, MatTableModule} from '@angular/material';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatNativeDateModule, MatSliderModule, DateAdapter} from '@angular/material';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderHttpModule, SPINNER, POSITION, PB_DIRECTION} from 'ngx-ui-loader';
import {CommonService} from './_services/common.service';
import { HttpClientModule} from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { TicketComponent } from './pages/ticket/ticket.component';
import { ComplaintComponent } from './pages/complaint/complaint.component';
import { ReportComponent } from './pages/report/report.component';
import { SidenavComponent } from './pages/sidenav/sidenav.component';
import { AutocompleteComponent } from './google-places.component';
import { Base64Pipe } from './base64.pipe';
import { ManageUserComponent } from './pages/users/manage-user/manage-user.component';
import {GeoLocationService} from './_services/geo-location.service';
import {AudioRecordingService} from './_services/audio-recording.service';
import {environment} from '../environments/environment';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgxImgZoomModule } from 'ngx-img-zoom';
import { SanitizeUrlPipe } from './sanitize-url.pipe';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import {NgbPaginationModule, NgbAlertModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatTabsModule} from '@angular/material/tabs';

// import { ChartModule } from '@syncfusion/ej2-angular-charts';
// import { GridModule } from '@syncfusion/ej2-angular-grids';
// import { PageService } from '@syncfusion/ej2-angular-grids';
// import { AccumulationChartModule, ExportService } from '@syncfusion/ej2-angular-charts';
// import { PieSeriesService, AccumulationTooltipService, AccumulationDataLabelService } from '@syncfusion/ej2-angular-charts';
// import {
//   LineSeriesService, DateTimeService, DataLabelService, StackingColumnSeriesService, CategoryService,
//   StepAreaSeriesService, SplineSeriesService, ScrollBarService, ChartAnnotationService, LegendService, TooltipService, StripLineService,
//   SelectionService, ScatterSeriesService, ZoomService, ColumnSeriesService
// } from '@syncfusion/ej2-angular-charts';

import { ForgotPasswordComponent } from './pages/password-management/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/password-management/reset-password/reset-password.component';
import { VerifyEmailComponent } from './pages/users/verify-email/verify-email.component';
import { GraphComponent } from './pages/graph/graph.component';
import { GsPlayerModule} from 'gs-player';
import { RecordRtcComponent } from './pages/record-rtc/record-rtc.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.threeStrings,
  bgsOpacity: 0.2,
  overlayColor: 'rgba(255,255,255,0.8)',
  // overlayColor: 'rgba(0,0,0,0.1)',
  fgsType: 'three-strings',
  fgsColor: '#17A0DB',
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 5,
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    TicketComponent,
    ComplaintComponent,
    ReportComponent,
    SidenavComponent,
    Base64Pipe,
    ManageUserComponent,
    SanitizeUrlPipe,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    GraphComponent,
    AutocompleteComponent,
    RecordRtcComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    BrowserModule,
    MatFormFieldModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatMenuModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapAPI
    }),
    NgxImageZoomModule,
    NgxImgZoomModule,
    NgxAudioPlayerModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbModule,
    MatTabsModule,
    GsPlayerModule,
    // ChartModule,
    // AccumulationChartModule,
    // GridModule,
    // Specify FusionChartsModule as import
],
  providers: [CommonService, HttpClientModule, MatDatepickerModule, GeoLocationService, AudioRecordingService,
              AutocompleteComponent, RecordRtcComponent
    // , LineSeriesService, DateTimeService, ColumnSeriesService, DataLabelService, ZoomService, StackingColumnSeriesService,
    // CategoryService, StepAreaSeriesService, SplineSeriesService, ChartAnnotationService, LegendService, TooltipService, StripLineService,
    // PieSeriesService, AccumulationTooltipService, ScrollBarService, AccumulationDataLabelService, SelectionService, ScatterSeriesService
    // , PageService, ExportService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
