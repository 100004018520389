import {Component, OnInit, ViewChild, ViewEncapsulation,  ViewChildren, QueryList, AfterViewInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import {Router} from '@angular/router';
import { CommonService } from '../../_services/common.service';
import {NgbModal, NgbSlide} from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {errorValidateAdmin} from "../../_helpers/utility";

declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css'],
//   encapsulation: ViewEncapsulation.None,
//   styles: [`
//    .carousel-item
//   {
//     display:block;
//   }
//
//   .carousel-control-next-icon {
//       background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2381BD41' viewBox='0 0 24 24'%3e%3cpath d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.568 18.005l-1.414-1.415 4.574-4.59-4.574-4.579 1.414-1.416 5.988 5.995-5.988 6.005z'/%3e%3c/svg%3e");
//   }
//  .carousel-control-prev-icon {
//      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2317A0DB' viewBox='0 0 24 24'%3e%3cpath d='M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12-5.373-12-12-12-12 5.373-12 12zm7.58 0l5.988-5.995 1.414 1.416-4.574 4.579 4.574 4.59-1.414 1.416-5.988-6.006z'/%3e%3c/svg%3e");
//   }
// `],
//   animations: [
//     trigger('simpleTranslation', [
//       state('outright', style({ transform: `translateX(100%)` })),
//       state('outleft', style({ transform: `translateX(-100%)` })),
//       transition('*=>inright', [
//         style({transform: `translateX(-100%)`}),
//         animate('2s ease-in', style({ transform: `translateX(0)` }))
//       ]),
//       transition('*=>inleft', [
//         style({transform: `translateX(100%)`}),
//         animate('2s ease-in', style({ transform: `translateX(0)` }))
//       ]),
//       transition('*=>outleft', [
//         animate('2s ease-in', style({ transform: `translateX(-100%)` }))
//       ]),
//       transition('*=>outright', [
//         animate('2s ease-in', style({ transform: `translateX(100%)` }))
//       ]),
//     ])
//   ]
})
export class GraphComponent implements OnInit {
  @ViewChildren(NgbSlide) slides: QueryList<NgbSlide>
  slideControl: any[] = [];
  userToken: any;
  userData: any;
  userRole: any;
  bankTitle: any;
  bankCategories: any;
  bankSeries: any;
  vendorTitle: any;
  vendorCategories: any;
  vendorSeries: any;
  timeTitle: any;
  timeSeries: any;
  public bankOptions: any;
  public vendorOptions: any;
  public timeOptions: any;

  constructor(
    private router: Router,
    private service: CommonService,
    private modalService: NgbModal,
    private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit() {
    this.initProcess();
  }


  onSlide(event) {
    this.slides.forEach((x, index) => {
      if (x.id == event.current) {
        this.slideControl[index] = 'in' + event.direction;
      }
      if (x.id == event.prev) {
        this.slideControl[index] = 'out' + event.direction;
      }
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    setTimeout(() => {
      this.slides.forEach((x, index) => {

        this.slideControl[index] = index ? 'outleft' : 'inleft';
      });
    });
  }

  initProcess(){
    this.userToken = (localStorage.getItem('userToken'));
    const authData = (localStorage.getItem('authData'));
    this.userData = JSON.parse(authData);
    this.userData = this.userData[0].user;
    this.userRole = this.userData.user_role_id;
    if (!this.userToken) {
      this.router.navigate(['/']);
    }
    this.getDasboardGraphsData();
  }

  getDasboardGraphsData(){

    this.ngxService.start();
    this.service.postRequestCreator('get-dashboard-graphs', null, this.userToken).subscribe((serviceresponse) => {
      this.ngxService.stop();
      const response = serviceresponse;
      if (response['code'] === 200) {
        this.bankTitle = response['result'].bankGraph.title;
        this.bankCategories = response['result'].bankGraph.bankCategories;
        this.bankSeries = response['result'].bankGraph.bankSeries;
        this.vendorTitle = response['result'].vendorGraph.title;
        this.vendorCategories = response['result'].vendorGraph.vendorCategories;
        this.vendorSeries = response['result'].vendorGraph.vendorSeries;
        this.timeTitle = response['result'].timeGraph.title;
        this.timeSeries = response['result'].timeGraph.timeSeries;
        if (this.userRole==1 || this.userRole==3) {
          this.createBankGraph();
        }
        if (this.userRole==1 || this.userRole==2) {
          this.createVendorGraph();
        }
        this.createAvgTimeGraph();
      }
    }, error => {
      this.ngxService.stop();
      errorValidateAdmin(error);
    });
  }

  createBankGraph() {
    // for (let i = 0; i < this.bankSeries.length; i++){
    //   this.bankSeries[i].data = Object.values(this.bankSeries[i].data);
    // }
    this.bankOptions = {
      chart: {
        type: 'column',
        backgroundColor: 'rgba(23, 160, 219, 0.05)'
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
            ]
          },
        },
        enabled: true,
      },
      navigation: {
        buttonOptions: {
          align: 'right',
          verticalAlign: 'top',
          y: 0,
          x: -35,
          theme: {
            fill: 'rgba(23, 160, 219, 0)'
          }
        }
      },
      title: {
        text: ''
      },

      legend: {
        enabled: false,
        align: 'right',
        verticalAlign: 'middle'
      },

      credits: {
        enabled: false
      },
      xAxis: {
        categories: this.bankCategories
      },

      yAxis: {
        min: 0,
        title: {
          text: 'Number of Tickets'
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || 'gray'
          }
        }
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      },
      series: this.bankSeries
    };

    Highcharts.chart('bank-container', this.bankOptions).reflow();
  }

  createVendorGraph() {
    // for (let i = 0; i < this.vendorSeries.length; i++) {
    //   this.vendorSeries[i].data = Object.values(this.vendorSeries[i].data);
    // }

    // this.vendorOptions = {
    //   chart: {
    //     type: 'column',
    //     style: {
    //       zoom: 0.99
    //     }
    //   },
    //
    //   exporting: {
    //     buttons: {
    //       contextButton: {
    //         menuItems: [
    //           'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
    //         ]
    //       },
    //     },
    //     enabled: true,
    //   },
    //   navigation: {
    //     buttonOptions: {
    //       align: 'right',
    //       verticalAlign: 'top',
    //       y: 0,
    //       x: -35
    //     }
    //   },
    //
    //   title: {
    //     text: ''
    //   },
    //
    //   legend: {
    //     enabled: false,
    //     align: 'right',
    //     verticalAlign: 'middle'
    //   },
    //
    //   credits: {
    //     enabled: false
    //   },
    //
    //   xAxis: {
    //     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    //   },
    //
    //   yAxis: {
    //     allowDecimals: false,
    //     min: 0,
    //     title: {
    //       text: 'Number of Tickets'
    //     }
    //   },
    //
    //   tooltip: {
    //     formatter: function () {
    //       return '<b>' + this.series.userOptions.stack + '</b><br/>' +
    //         this.series.name + ': ' + this.y + '<br/>' +
    //         'Total: ' + this.point.stackTotal;
    //
    //     }
    //   },
    //
    //   plotOptions: {
    //     column: {
    //       stacking: 'normal'
    //     }
    //   },
    //
    //   series: this.vendorSeries ? this.vendorSeries : []
    // };

    this.vendorOptions = {
      chart: {
        type: 'column',
        backgroundColor: 'rgba(23, 160, 219, 0.05)'
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
            ]
          },
        },
        enabled: true,
      },
      navigation: {
        buttonOptions: {
          align: 'right',
          verticalAlign: 'top',
          y: 0,
          x: -35,
          theme: {
            fill: 'rgba(23, 160, 219, 0)'
          }
        }
      },
      title: {
        text: ''
      },

      legend: {
        enabled: false,
        align: 'right',
        verticalAlign: 'middle'
      },

      credits: {
        enabled: false
      },
      xAxis: {
        categories: this.vendorCategories
      },

      yAxis: {
        min: 0,
        title: {
          text: 'Number of Tickets'
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || 'gray'
          }
        }
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      },
      series: this.vendorSeries
    };

    Highcharts.chart('vendor-container', this.vendorOptions).reflow();
  }

  createAvgTimeGraph() {
    this.timeOptions = {
      chart: {
        backgroundColor: 'rgba(23, 160, 219, 0.05)'
      },
      title: {
        text: ''
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
            ]
          },
        },
        enabled: true,
      },
      navigation: {
        buttonOptions: {
          align: 'right',
          verticalAlign: 'top',
          y: 0,
          x: -35,
          theme: {
            fill: 'rgba(23, 160, 219, 0)'
          }
        }
      },

      yAxis: {
        title: {
          text: 'Number of Days'
        }
      },

      xAxis: {
        accessibility: {
          rangeDescription: 'Months: Jan to Dec'
        },
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      tooltip: {
        // headerFormat: '<b>{point.x}</b><br/>',
        // pointFormat: 'Days: {point.y}'
        formatter: function() {
          const isFloat = Number(this.y) === this.y && this.y % 1 !== 0;
          if (isFloat) {
            const n = Math.abs(this.y); // Change to positive
            // const decimal = Math.ceil((n - Math.floor(n)) * 10);
            const decimal = ((n - Math.floor(n)));
            const hours = parseFloat((decimal * 8).toString()).toFixed(2);
            return '<b>' + this.x + '</b><br/>' +
              // tslint:disable-next-line:radix
              this.series.name + ': ' + parseInt(this.y) + 'D ' + Math.ceil(parseFloat(hours)) + 'H' + '<br/>';
          } else {
            return '<b>' + this.x + '</b><br/>' +
              this.series.name + ': ' + (this.y) + 'D' + '<br/>';
          }

        }
      },
      // legend: {
      //   layout: 'vertical',
      //   align: 'right',
      //   verticalAlign: 'middle'
      // },

      credits: {
        enabled: false
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          }
        }
      },

      series: this.timeSeries,

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }

    };
    Highcharts.chart('avg-time-container', this.timeOptions).reflow();
  }

}
