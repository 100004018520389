import {Component, ElementRef, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { CommonService } from '../../_services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {errorValidateAdmin, email, date, diff_years, MustMatch, number,
        passwordPolicy, formatDate, minValue, maxValue} from '../../_helpers/utility';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DateAdapter } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Track } from 'ngx-audio-player';
import { AudioRecordingService } from '../../_services/audio-recording.service';
import { DomSanitizer } from '@angular/platform-browser';
import {AutocompleteComponent} from '../../google-places.component';
import {RecordRtcComponent} from '../record-rtc/record-rtc.component';

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.css']
})
export class ComplaintComponent implements OnInit {
  @ViewChild('figAudio', {static: false}) figAudio; // audio tag reference
  @ViewChild('videoElement', {static: false}) public videoElement: ElementRef;
  @ViewChild(RecordRtcComponent, {static: false}) child;

  userToken: any;
  userData: any;
  userRole: any;

  isRecording = false;
  recordedTime;
  blobUrl;
  blobFile;

  audSrc = 'assets/audio/sample.mp3';

  msbapAudioTitle = 'No File Selected';
  msbapAudioUrl = '';
  msaapDisplayTitle = true;
  msaapDisplayPlayList = false;
  msaapPageSizeOptions = [1];
  msaapDisplayVolumeControls = true;
  msaapDisablePositionSlider = false;

// Material Style Advance Audio Player Playlist
  msaapPlaylist: Track[] = [
    {
      title: this.msbapAudioTitle,
      link: this.msbapAudioUrl
    }
  ];

  // tslint:disable-next-line:variable-name
  manageComplaintFormGroup: FormGroup;
  id: any = '';
  receivedParentMessages = ' Raise Complaint';
  isEdit = false;
  buttonText = 'Save';
  route: any;
  // tslint:disable-next-line:variable-name
  issue_id: any;
  // tslint:disable-next-line:variable-name
  image_path: any;
  // tslint:disable-next-line:variable-name
  audio_path: any;
  // tslint:disable-next-line:variable-name
  video_path: any;
  selectedImage: any;
  selectedAudio: any;
  selectedVideo: any;
  issuesList: any;
  audioProof = 1;
  videoProof = 1;
  videoUrl: any;
  videoLoaded = false;
  loadingState = false;
  constructor(
    private router: Router,
    private service: CommonService,
    private formBuilder: FormBuilder,
    private activeroute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private dateAdapter: DateAdapter<Date>,
    private http: HttpClient,
    private audioRecordingService: AudioRecordingService,
    private sanitizer: DomSanitizer
  ) {

    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isRecording = false;
    });

    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.recordedTime = time;
    });

    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      this.blobFile = data.blob;
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
    });

    this.activeroute.queryParams.subscribe(params => {
      if (params['id']) {
        this.id = params['id'];
      }
    });

    this.dateAdapter.setLocale('en-GB');
  }

  resetProof() {
    this.image_path = './assets/img/no-image.jpg';
    this.videoUrl = null;
    this.selectedVideo = null;
    this.selectedImage = null;
    this.selectedAudio = null;
    this.videoLoaded = false;
    this.msbapAudioTitle = 'No File Selected';
    this.msbapAudioUrl = '';
    this.msaapPlaylist = [
      {
        title: this.msbapAudioTitle,
        link: this.msbapAudioUrl
      }
    ];
    this.service.actionVideoBlob = null;
  }

  startRecording() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
    }
  }

  clearRecordedData() {
    this.blobUrl = null;
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.abortRecording();
  }

  audFileSelected(event: any) {
    const files = event.target.files[0];
    if (!files) {
      this.selectedAudio = null;
    } else {
      this.selectedAudio = files;
    }
    if (event.target.files && event.target.files[0]) {
      const audSrc = URL.createObjectURL(event.target.files[0]);
      this.msbapAudioTitle = event.target.files[0].name;
      this.msaapPlaylist[0].link = this.audSrc;
      this.msaapPlaylist[0].title = event.target.files[0].name;
    }
  }

  public readUrl(event) {
    this.loadingState = true;
    this.videoLoaded = false;
    if (event.target.files && event.target.files[0]) {
      this.selectedVideo = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (data: any) => {
        this.playVideo(data.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      this.selectedVideo = null;
    }
  }
  public changeAudioProof(event) {
    this.audioProof = event.value;
  }
  public changeVideoProof(event) {
    this.videoProof = event.value;
    this.selectedVideo = null;
    this.service.actionVideoBlob = null;
  }

  public playVideo(url) {
    this.loadingState = false;
    this.videoLoaded = true;
    this.videoUrl = url;
    if (this.videoElement !== undefined) {
      this.videoElement.nativeElement.load();
    }
  }

  ngOnInit() {
    this.image_path = './assets/img/no-image.jpg';
    this.userToken = (localStorage.getItem('userToken'));
    const authData = (localStorage.getItem('authData'));
    this.userData = JSON.parse(authData);
    this.userData = this.userData[0].user;
    this.userRole = this.userData.user_role_id;
    if (!this.userToken) {
      this.router.navigate(['/']);
    }
    if (this.userRole != 4) {
      this.router.navigate(['/home']);
    }
    // tslint:disable-next-line:prefer-const
    var _this = this;

    // JQuery(".from.datetimepicker")
    //   .datetimepicker({
    //     useCurrent: false,
    //     format: "DD-MM-YYYY",
    //     viewDate: moment(new Date())
    //       .hours(0)
    //       .minutes(0)
    //       .seconds(0)
    //       .milliseconds(0)
    //   })
    //   .on("dp.change", function(e) {
    //     let name = e.target.name;
    //     _this.manageComplaintFormGroup.patchValue({
    //       [name]: e.target.value
    //     });
    //   });
    // JQuery(".to.datetimepicker")
    //   .datetimepicker({
    //     useCurrent: false,
    //     format: "DD-MM-YYYY",
    //     viewDate: moment(new Date())
    //       .hours(23)
    //       .minutes(59)
    //       .seconds(0)
    //       .milliseconds(0)
    //   })
    //   .on("dp.change", function(e) {
    //     let name = e.target.name;
    //     _this.manageComplaintFormGroup.patchValue({
    //       [name]: e.target.value
    //     });
    //   });
    //

    if (this.id) {
      this.isEdit = true;
      this.receivedParentMessages = 'View/Edit Complaint';
      this.buttonText = 'Update';
      this.manageComplaintFormGroup = this.formBuilder.group({
        id: [''],
        issue_id: ['', [Validators.required]],
        other_issue: [''],
        comment: [''],
        image: [''],
        video: [''],
        audio: ['']
      });
      this.getComplaintDetails();
    } else {
      this.manageComplaintFormGroup = this.formBuilder.group({
        id: [''],
        issue_id: ['', [Validators.required]],
        other_issue: [''],
        image: [''],
        video: [''],
        audio: [''],
        comment: ['']
        });
    }
    this.getIssuesList();
  }

  checkForNumber($e) {
    if ( !($e.keyCode == 8 || $e.keyCode == 37 || $e.keyCode == 39 || $e.keyCode == 9 || ($e.keyCode >= 48 && $e.keyCode <= 57) || ($e.keyCode >= 97 && $e.keyCode <= 105))) {
      $e.preventDefault();
    }
  }

  onSelectImage(e) {
    const files = e.target.files[0];

    if (!files) {
      this.selectedImage = null;
    } else {
      this.selectedImage = files;
    }

    if(e.target.files){
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event:any) => {
        this.image_path = event.target.result;
      }
    }
  }

  onSelectVideo(e) {
    const files = e.target.files[0];

    if (!files) {
      this.selectedImage = null;
    } else {
      this.selectedImage = files;
    }

    if(e.target.files){
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event:any) => {
        this.video_path = event.target.result;
      }
    }
  }

  checkLength($e, maxLength) {
    if ($e.target.value.length == maxLength) {
      if ( !($e.keyCode == 8 || $e.keyCode == 37 || $e.keyCode == 39 || $e.keyCode == 9 )) {
        $e.preventDefault();
      }
    }
  }

  checkIssue(e, divID) {
    // tslint:disable-next-line:radix
    if (parseInt(e.value) === (-1)) {
      document.getElementById(divID).style.display = 'block';
    } else {
      document.getElementById(divID).style.display = 'none';
    }
  }

  checkOtherIssue(e, divID) {
    if (e.target.value) {
      document.getElementById(divID).style.display = 'none';
    } else {
      document.getElementById(divID).style.display = 'block';
    }
  }

  getComplaintDetails() {
    this.ngxService.start();
    this.service.postRequestCreator('complaint-detail', {id : this.id}, this.userToken).subscribe(
      (serviceresponse: any) => {
        this.ngxService.stop();
        if (serviceresponse.code === 200) {
          this.manageComplaintFormGroup.patchValue({
            id: serviceresponse.result.id,
            issue_id: serviceresponse.result.issue_id,
            other_issue: serviceresponse.result.other_issue,
            comment: serviceresponse.result.comment,
            image: serviceresponse.result.image_path,
            audio: serviceresponse.result.audio_path,
            video: serviceresponse.result.video_path
          });

          this.image_path = serviceresponse.result.image_path;
          this.video_path = serviceresponse.result.video_path;
          this.audio_path = serviceresponse.result.audio_path;

        } else {
          Swal.fire('Oops...', 'Something went wrong!', 'error');
        }
      },
      error => {
        this.ngxService.stop();
        errorValidateAdmin(error);
      }
    );
  }

  getIssuesList() {
    this.ngxService.start();
    this.service.getRequest('get-issues-list').subscribe( (serviceresponse: any) => {
      this.ngxService.stop();
      if (serviceresponse.code === 200) {
          this.issuesList = serviceresponse.result ? serviceresponse.result : [];
        }
      },
      error => {
        this.ngxService.stop();
        errorValidateAdmin(error);
      }
    );
  }

  saveComplaint(formData) {

    if (this.manageComplaintFormGroup.invalid) {
      // Swal.fire('Oops...', 'Please Fill the form correctly!', 'error');
      // tslint:disable-next-line:radix
      if (parseInt(formData.issue_id) === (-1)) {
        if (!formData.other_issue) {
          document.getElementById('other_issue_error').style.display = 'block';
          return;
        }
      }
      return;
    }
    // tslint:disable-next-line:radix
    if (parseInt(formData.issue_id) === (-1)) {
      if (!formData.other_issue) {
        document.getElementById('other_issue_error').style.display = 'block';
        return;
      }
    }
    this.ngxService.start();
    if (this.isEdit) {
      this.route = 'update-complaint';
      formData.id = this.id;
    } else {
      this.route = 'register-complaint';
    }

    const myFormData = new FormData();
    myFormData.append('id', formData.id);
    myFormData.append('issue_id', formData.issue_id);
    myFormData.append('other_issue', formData.other_issue);
    myFormData.append('comment', formData.comment);
    myFormData.append('image', this.selectedImage);
    if (this.audioProof == 1) {
      myFormData.append('audio', this.selectedAudio);
    } else {
      myFormData.append('audio', this.blobFile);
    }

    if (this.videoProof == 1) {
      myFormData.append('video', this.selectedVideo);
    } else {
      myFormData.append('video', this.service.actionVideoBlob);
    }

    this.service.postRequestCreatorWithFile(this.route, myFormData, this.userToken).subscribe(
      (serviceresponse: any) => {
        this.ngxService.stop();
        if (serviceresponse.code === 200 || serviceresponse.code === 202) {
          Swal.fire({
            title: serviceresponse.message,
            showConfirmButton: true,
          });
          this.router.navigate(['/ticket']);
        } else if (serviceresponse.code === 400) {
          Swal.fire('Oops...', 'Please Fill the form correctly!', 'error');
        } else {
          Swal.fire('Oops...', 'Something went wrong!', 'error');
        }
      },
      error => {
        this.ngxService.stop();
        errorValidateAdmin(error);
      }
    );
  }

}
export let JQuery: any = window['jQuery'];
