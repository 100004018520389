import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup , Validators, FormControl} from '@angular/forms';
import {Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CommonService} from '../../../_services/common.service';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {

  token: any;
  userEmail: any;
  constructor(
    private fb: FormBuilder,
    @Inject(DOCUMENT) document,
    private service: CommonService,
    private http: HttpClient,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private activeroute: ActivatedRoute,
  ) {
    this.activeroute.queryParams.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
        this.getUserByEmailToken(this.token);
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Invalid Token',
          icon: 'error',
          showConfirmButton: true
        });
        this.router.navigate(['']);
      }
    });
  }
  data: any;

  ngOnInit() {
  }

  getUserByEmailToken(token) {
    this.ngxService.start();
    const data = { token };
    this.service.postRequest('verify-user-by-email-token', data).subscribe(serviceresponse => {
      this.ngxService.stop();
      const response = serviceresponse;
      if ( response['status'] === 'error' && response['code'] === 400) {
        Swal.fire({
          text: 'Invalid Token',
          showConfirmButton: true
        });
        this.router.navigate(['']);
      } else if ( response['status'] === 'error' && response['code'] === 401) {
        Swal.fire({
          text: 'Token has expired. Please register again.',
          showConfirmButton: true
        });
        this.router.navigate(['/manage-user']);
      } else if ( response['status'] === 'error' && response['code'] === 403) {
        Swal.fire({
          text: 'Email has been already verified.',
          showConfirmButton: true
        });
        this.router.navigate(['']);
      } else if ( response['status'] === 'success') {
        Swal.fire({
          text: 'Thank you. You have successfully completed User Registration.',
          showConfirmButton: true
        });
        this.router.navigate(['']);
      } else {
        Swal.fire({
          text: 'Something went wrong!',
          showConfirmButton: true
        });
        this.router.navigate(['']);
      }
    }, error => {
      this.ngxService.stop();
      Swal.fire({
        text: 'Something went wrong!',
        showConfirmButton: true
      });
      this.router.navigate(['']);
    });
  }

}
